import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Validation } from 'src/Constants/constants';
import { useHistory } from "react-router-dom";
import { APP_CONFIG } from '../../Constants/Config';
import OutSideCloseHelper from '../SharedComponent/OutSideCloseHelper';
import PayPalCheckout from '../Checkout/Paypal';
import { ROUTES_PATH } from 'src/Constants/Routes';
import BlockUI from "../SharedComponent/BlockUI/BlockUI";
import { ispointOutsideCircle } from 'src/Helpers/Util';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaEuroSign } from 'react-icons/fa';
import {customAlphabet} from "nanoid";

const FooterCheckoutMenu = (props) => {
    console.info("FooterCheckoutMenu", props);
    console.info("FooterCheckoutMenu Wish", props.wish);

    const { isPaypalActive, isScriptLoaded = false, isLocationAllowed, wish } = props;
    const isGeolocationAllowed = APP_CONFIG.ALLOW_GEOPOSITION;
    const pageText = props.webLangContext ? props.webLangContext.footerCheckout : {};
    const modalRef = useRef(null);
    // const imgPath = APP_CONFIG.IMAGE_PATH;
    const [paymentMenu, setPaymentMenu] = useState(false);
    const [geolocationError, setGeolocationError] = useState((isGeolocationAllowed && !isLocationAllowed ) ? pageText.locationDeniedError + (isPaypalActive ? ' ' + pageText.paypalOptionActive : '') : null);
    // const [paypalAnimate, setPaypalAnimate] = useState(false);
    const [cashBtnAnimate, setCashBtnAnimate] = useState(false);
    const [BlockUIMessage, setBlockUIMessage] = useState('Waiting');
    // const [payMethod, setPayMethod] = useState('');
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    // let console.log('height', document.getElementById('orderWrapper').clientHeight);
    let [orderWrapperHeight, setOrderWrapperHeight] = useState();
    const [checkoutButtonClicked, setCheckoutButtonClicked] = useState(false);
    let history = useHistory();
    const [showLoader, setShowLoader] = useState(false);
    let geolocationWatcher = null;
    let geolocationNav = null;

    useEffect(() => {
        return () => onComponentUnmount;
    });

    useEffect(() => {
        setOrderWrapperHeight(document.getElementById('orderWrapper').clientHeight);
      }, [showConfirmationAlert]);

    const handleOrderConfirmation = () => {
        setShowConfirmationAlert(true);

        // setOrderWrapperHeight(300)
    };

    const onCheckoutUser = () => {
        if (props.cartList && props.cartList.length > 0 && !checkoutButtonClicked) {
            setCheckoutButtonClicked(true);
            setShowLoader(true);
            if(isGeolocationAllowed){
                setOrderWrapperHeight(document.getElementById('orderWrapper').clientHeight);
                getUserGeolocation();
            } else {
                onCreateOrder();
            }
        }
    }

    const onCreateOrder = () => {
        console.log("wish", props.wish)
        // let animateBtn = payMethod === 'cash' ? setCashBtnAnimate : setPaypalAnimate;
        // animateBtn(true);
        const payMethod = 'cash';
        setCashBtnAnimate(true);
        setBlockUIMessage('Creating Order');
        const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);
        const orderId = nanoid();
        props.userCreateOrder({ payMethod, wish: props.wish, orderId })
            .then(data => {
                setCheckoutButtonClicked(false);
                console.log(data)
                if (data === Validation.success) {
                    history.push(ROUTES_PATH.INVOICE);
                }
                else {
                    history.push(ROUTES_PATH.ERROR)
                }
                setCashBtnAnimate(false);
            }).catch(error => {
                console.error(error);
                setCheckoutButtonClicked(false);
            })
    }

    const onChange = ({ coords }) => {
        console.log("User Allowed Permissions");
        setGeolocationError(!isLocationAllowed ? pageText.locationDeniedError + (isPaypalActive ? ' ' + pageText.paypalOptionActive : '') : null);
        setShowLoader(false);

        const userLatLong = {
            latitude: coords.latitude,
            longitude: coords.longitude,
        };

        if (ispointOutsideCircle(userLatLong, userLatLong)) {
            onCreateOrder();
        } else {
            const error_msg = pageText.userOutsideBounds;
            setGeolocationError(error_msg);
            setCheckoutButtonClicked(false);
            setShowConfirmationAlert(false)

        }
    };

    const onError = (error) => {
        console.log("User Denied Permissions");
        const error_msg = pageText.locationDeniedError + ' ' + (isPaypalActive ? pageText.paypalOptionActive : '.' + pageText.resetDeniedLocation);
        setGeolocationError(error_msg);
        setShowConfirmationAlert(false)
        setShowLoader(false);
        setCheckoutButtonClicked(false);
    };




    const getUserGeolocation = () => {

        // getUserConfirmationOnGeoLocation().then().catch();

        setShowLoader(true);
        geolocationNav = navigator.geolocation;
        if (!geolocationNav) {
            console.error('Browser doesnt support Geolocation');
            setShowLoader(false);
            // setError('Geolocation is not supported');
            // return;
        }
        geolocationWatcher = geolocationNav.getCurrentPosition(onChange, onError);
    }

    const onComponentUnmount = () => {
        console.log("++++++++++++++++++Component Unmount+++++++++++++++++++");
        if (geolocationNav) {
            geolocationNav.clearWatch(geolocationWatcher);
            geolocationNav = null;
            geolocationWatcher = null;
        }
    }

    const onCancelCashPayment = () => {
        setShowConfirmationAlert(false);
        // setOrderWrapperHeight(document.getElementById('orderWrapper').clientHeight);
    }

    const isPaymentOptionsVisible = () => {
        const displayOption = paymentMenu ? 'flex' : 'none';
        return {
            display: displayOption
        }
    }
    const getPaypalPaymentButton = () => {
        if (!isScriptLoaded || !isPaypalActive)
            return null;

        return <div>
            <PayPalCheckout wishText={wish} cartList={props.cartList} tableName={props.tableName} userId={props.userId} userCreateOrder={props.userCreateOrder} updateOrder={props.updateOrder} />
            {/*  {!paypalAnimate ? <>

                </> :
                <div className="loadinganimation_checkout">
                    <img src={`${imgPath}/time_dAsset-3.svg`} loading="lazy" width="33" height="33" data-w-id="84d93805-0305-47f3-67b2-22599bc38670" alt=""/>
                </div>
                } */}
        </div>
    }

    const getConfirmationText = () => {
        const text = isGeolocationAllowed ? pageText.geoLocationAskMsg : pageText.confirmationAlertMsg;
        return <h3>{text}</h3>;
    }

    const getPaymentOptions = () => {
        return <React.Fragment>
            {props.cashPayment && <div className="payment-method cash-option" onClick={() => handleOrderConfirmation()}>
                {!cashBtnAnimate ? <>
                    <FaEuroSign className="order-euro-icon" />
                    <div className="text-block-306">{pageText.paymentMethodCash}</div>
                </> :
                    <div className="loadinganimation_checkout2" style={{ display: 'flex' }}>
                        <svg className="order-time-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.48 437.57">
                            <g data-name="Layer 1">
                                <path d="M123.6,218.63c-8.52-3.33-16.28-6.66-23.54-11-29.74-18-50.06-43.88-63.79-75.35C26.09,108.91,21.31,84.38,20.52,59c-.08-2.81-.51-4.18-3.88-4.87C6.32,52.06.39,44.49.09,33.93,0,29.27,0,24.6.17,20,.62,9.08,8.08.93,19,.46,32-.1,45,.15,58,.15q119.46,0,238.91,0c4.9,0,10-.25,14.68,0,5.1.33,10.34,4.14,13.35,9.24,2,3.44,3.54,7.12,3.43,11.3-.12,4.49.07,9-.07,13.48-.35,10.63-6.33,18-16.72,20-3.05.58-3.66,1.7-3.73,4.45-.71,25.36-5.3,49.93-15.55,73.25-15.71,35.73-39.37,64.24-75.1,81.6-1.94.94-4,1.76-6,2.59-1.82.76-3.67,1.46-5.62,2.24,1,1.83,2.67,1.92,4,2.47,30.17,12.13,52.79,33,69.84,60.3,17.7,28.3,26.56,59.32,28.06,92.55.1,2.16.4,4.32.39,6.48s.94,2.71,2.91,3.15c11.54,2.58,17.44,9.74,17.45,21.41,0,6.12.85,12.33-1,18.36a19.84,19.84,0,0,1-17.46,14.19c-2.15.2-4.32.17-6.49.17-93.63,0-187.26-.13-280.89.14C7.09,437.61-.52,426.66,0,414.93c.2-4.33-.07-8.67.2-13,.59-9.55,7.12-17,16.57-18.51,3.1-.49,3.71-1.72,3.78-4.48.65-25,5.11-49.31,15.11-72.34,17.15-39.52,43.67-70,84.57-86.38C121.14,219.87,122,219.41,123.6,218.63Zm41.13-164v0c-28.31,0-56.63.08-84.94-.09-3.85,0-4.74,1-4.51,4.77,1,15.84,3.24,31.39,8.22,46.53,1,3,2.39,3.64,5.27,3.64q75.45-.11,150.9,0c2.88,0,4.3-.65,5.26-3.65a187.69,187.69,0,0,0,8.22-46c.24-4.09-.66-5.34-5-5.31C220.36,54.77,192.55,54.67,164.73,54.67Zm-.61,273.53H201.6c12.5,0,25-.11,37.49.08,3.83.07,4.46-.72,3-4.36a124.51,124.51,0,0,0-29.18-43.67c-26.3-25.3-63.89-27.12-92.26-4.32-15.85,12.75-26.51,29.18-34.2,47.8-1.68,4.07-.7,4.56,3.21,4.54C114.47,328.13,139.3,328.2,164.12,328.2Z" /></g>
                        </svg>
                        {/* <img className="syncing" src={`${imgPath}/time_dAsset-3.svg`} loading="lazy" width="33" height="33" data-w-id="84d93805-0305-47f3-67b2-22599bc38670" alt="" /> */}
                    </div>
                }
            </div>}
            {isPaypalActive ? getPaypalPaymentButton() : null}
        </React.Fragment>
    }
    console.log("checkout", pageText)
    OutSideCloseHelper(modalRef, () => setPaymentMenu(false));
    return (
        <React.Fragment>
            <div ref={modalRef} data-hover="" data-delay="0" className="orderOpenBtn" style={paymentMenu ? { transform: `translateY(-${orderWrapperHeight}px)` } : { transform: 'translateY(0)' }}>
                {props.cartList && !!props.cartList.length && props.showCart && <div className="order-box-text">
                    <div className="order-box-wrapper"
                        onClick={() => {
                            console.log('height', document.getElementById('orderWrapper').clientHeight);
                            setOrderWrapperHeight(document.getElementById('orderWrapper').clientHeight);
                            setPaymentMenu(!paymentMenu)
                        }}
                    >
                        {pageText.checkout}
                        <BsFillCheckCircleFill className="order-bar-icon" />
                    </div>
                </div>}

                {<nav id="orderWrapper" className={paymentMenu ? "w--open" : "w--closed"} >
                    <div className="order-block">

                        <div className={"order-confirm-container " + (showConfirmationAlert ? 'd-block': 'd-none')} >
                            {getConfirmationText()}

                            <div className="button-group-container">
                                <button className="confirm" onClick={() => onCheckoutUser()}>
                                    {pageText.confirmText}
                                </button>
                                <button className="cancel" onClick={() => onCancelCashPayment()}>
                                    {pageText.cancelText}
                                </button>
                            </div>

                        </div>
                       <div className={"payment-options " + (showConfirmationAlert ? 'd-none': 'd-block')}>
                            {geolocationError && <div className="alert-error text-center">{geolocationError}</div>}
                            {getPaymentOptions()}
                        </div>

                        <div className="order-block-info">{pageText.paymentInfo}</div>
                        <div className="linktoagreement">
                            <a href="#" className="link-7">{pageText.termsAndCondition}</a>
                        </div>

                    </div>



                </nav>}

            </div>
            <BlockUI title={BlockUIMessage} blocking={showLoader} />
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        isLocationAllowed: state.CategoryListReducer.isLocationAllowed,
        userId: state.CategoryListReducer.userId,
        tableName: state.CategoryListReducer.tableName,
        cartList: state.CategoryListReducer.cartList,
		cashPayment: state.CategoryListReducer.cashPayment,
    }
}

export default connect(mapStateToProps)(FooterCheckoutMenu);
