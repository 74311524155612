import { CURRENCY_CODES } from '../Components/Checkout/paypal/PaypalCurrencyCodes';


const getApplicationContext = (orderData) => {
  return {
    "return_url": "http://localhost:3000/invoice",
    "cancel_url": "http://localhost:3000/invoice",
    "brand_name": "Gastron",
    "locale": orderData.locale,
    "user_action": "CONTINUE"
  }
}

const getCurrencyObj = (currency_code, value) => {
    if (value) {
        return { currency_code,
          "value": parseFloat(value).toFixed(2)
        }
    }
    return null;
}

const getBreakdown = (orderData) => {

    let item_total, shipping, handling, tax_total, shipping_discount, discount;

    // The subtotal for all items. Required if the request includes purchase_units[].items[].unit_amount.
    // Must equal the sum of (items[].unit_amount * items[].quantity) for all items. item_total.value can not be a negative number.
    item_total = orderData.cartList.reduce(function(sum, item) {
        return sum + parseFloat(item.price) * parseInt(item.count)
    }, 0)

    shipping = orderData.shipping || "0.00"; // TODO add logic
    handling = orderData.handling || "0.00"; // TODO add logic
    tax_total = orderData.tax_total || "0.00"; // TODO add logic
    shipping_discount = orderData.shipping_discount || "0.00"; // TODO add logic
    discount = orderData.discount || "0.00"; // TODO add logic

    return {
        item_total: getCurrencyObj(orderData.currency_code, item_total),
        shipping: getCurrencyObj(orderData.currency_code, shipping),
        handling: getCurrencyObj(orderData.currency_code, handling),
        tax_total: getCurrencyObj(orderData.currency_code, tax_total),
        shipping_discount: getCurrencyObj(orderData.currency_code, shipping_discount),
        discount: getCurrencyObj(orderData.currency_code, discount)
    }
}

const getItemsObj = (orderData) => {

  const items = orderData.cartList.map(currentItem => {
    return {
      name: currentItem.name,
      description: currentItem.information.substring(0, 120),
      "sku": currentItem.productNum,
      "category": "PHYSICAL_GOODS",
      "unit_amount": getCurrencyObj(orderData.currency_code, currentItem.price),
      "tax": getCurrencyObj(orderData.currency_code, currentItem.tax || "0.00"),
      "quantity": currentItem.count
    }
  })
    return items
}

export const getPaypalOrderRequest = (orderData) => {

  console.log("orderData is : " , orderData);
  const breakdown = getBreakdown(orderData);
  orderData.currency_code = orderData.currency_code || CURRENCY_CODES.EUR;

  /**
   * If you specify amount.breakdown, the amount equals item_total plus tax_total plus
   * shipping plus handling plus insurance minus shipping_discount minus discount.
   */
  const paypalOrder = {
      intent: "CAPTURE",
      application_context: getApplicationContext(orderData),
      order_id:orderData.orderId,
      purchase_units: [{
          "reference_id": `tableName:${orderData.tableName}-orderID:${orderData.orderId}`,
          "description": `Order ID: ${orderData.orderId} at: ${new Date()}`,
          "custom_id": '${orderData.userId}',
          "soft_descriptor": "Order For Table",
          "amount": {
              currency_code: orderData.currency_code,
              "value": breakdown.item_total.value,
              breakdown,
          },
          "items": getItemsObj(orderData),
      }]
  }
  return paypalOrder;
}

// https://developer.paypal.com/docs/business/checkout/set-up-standard-payments/
/** Sample Order Request
 * {
  "intent": "CAPTURE",
  "application_context": {
    "return_url": "https://example.com",
    "cancel_url": "https://example.com",
    "brand_name": "EXAMPLE INC",
    "locale": "en-US",
    "landing_page": "BILLING",
    "shipping_preference": "SET_PROVIDED_ADDRESS",
    "user_action": "CONTINUE"
  },
  "purchase_units": [
    {
      "reference_id": "PUHF",
      "description": "Sporting Goods",

      "custom_id": "CUST-HighFashions",
      "soft_descriptor": "HighFashions",
      "amount": {
        "currency_code": "EUR",
        "value": "230.00",
        "breakdown": {
          "item_total":
          {
              "currency_code": "EUR",
              "value": "180.00"
          },
          "shipping":
          {
              "currency_code": "EUR",
              "value": "30.00"
          },
          "handling":
          {
              "currency_code": "EUR",
              "value": "10.00"
          },
          "tax_total":
          {
              "currency_code": "EUR",
              "value": "20.00"
          },
          "shipping_discount":
          {
              "currency_code": "EUR",
              "value": "10"
          }        }
      },
      "items": [
        {
          "name": "T-Shirt",
          "description": "Green XL",
          "sku": "sku01",
          "unit_amount": {
            "currency_code": "EUR",
            "value": "90.00"
          },
          "tax": {
            "currency_code": "EUR",
            "value": "10.00"
          },
          "quantity": "1",
          "category": "PHYSICAL_GOODS"
        },
        {
          "name": "Shoes",
          "description": "Running, Size 10.5",
          "sku": "sku02",
          "unit_amount": {
            "currency_code": "EUR",
            "value": "45.00"
          },
          "tax": {
            "currency_code": "EUR",
            "value": "5.00"
          },
          "quantity": "2",
          "category": "PHYSICAL_GOODS"
        }
      ],
      "shipping": {
        "method": "United States Postal Service",
        "address": {
          "name": {
            "full_name":"John",
            "surname":"Doe"
          },
          "address_line_1": "123 Townsend St",
          "address_line_2": "Floor 6",
          "admin_area_2": "San Francisco",
          "admin_area_1": "CA",
          "postal_code": "94107",
          "country_code": "US"
        }
      }
    }
  ]
}
 */
