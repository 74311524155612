export const createOrder = `
mutation CreateOrder($input: OrderInput!) {
  createOrder(inputOrder: $input) {
    incomingOrderTs
    isPaid
    orderId
    payMethod
    tableName
    userId
    wish
    isCompleted
    products {
      isCooked
      isCooking
      isPaid
      price
      imageUrl
      productDurationTime
      productId
      productName
      productNum
      quantity
      timestampStart
    }
  }
}
`;

export const updatePayment = `
mutation UpdatePayment ($paymentInput: paymentInput! ){
  updatePayment(paymentInput: $paymentInput){
    isPaid
    orderId
    paymentMethod
    paypalOrderId
    paypalPayerId
    userId
  }
}`;

export const callWaiter = `
mutation CallWaiter($waiterInput: WaiterInput!) {
  callWaiter(waiterInput: $waiterInput) {
    table {
      tableName
      userId
      hasCustomerCall
     },
    userId
  }
}`;

export const sendInvoice = `
mutation SendInvoice($input: sendInvoiceInput!) {
  sendInvoice(sendInvoiceInput: $input) {
    userId
    email
  }
}
`;
