import './App.css';
import Routes from './Components/Routes/Routes';
import {connect} from 'react-redux';
import {useEffect} from 'react';
import Actions from "./Actions";

function App({webLang,setLanguage}) {
    useEffect(() => {
        // Checking for local storage data

        var persist = localStorage.getItem("_language");
        if(!persist){
            const browserLang = navigator.language || navigator.userLanguage;
            //browserLang === 'de' ? setLanguage('English') : '';

            switch (browserLang) {
                case 'de':
                    setLanguage('Deutsch');
                    break;
                case 'en-US':
                    setLanguage('English');
                    break;
                case 'en-GB':
                    setLanguage('English');
                    break;
                case 'en-AU':
                    setLanguage('English');
                    break;
                default:
                    setLanguage('Deutsch');
                    break;
            }

        }
        console.log({persist});
    }, [webLang]);
    return (

        <Routes/>


    );
}

const mapStateToProps = state => {
    return {
        webLangContext: state.CategoryListReducer.webLangContext,
        webLang: state.CategoryListReducer.webLang
    }
}


const mapDispatchToProps = dispatch => ({
    setLanguage: (payload) => dispatch(Actions.CategoryList.setLanguage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
