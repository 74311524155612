import React from 'react';
import FooterMenu from './FooterMenu';
import SearchComponent from '../SearchComponents/SearchComponent';
import FooterBuyMenu from './FooterBuyMenu';
import FooterCheckoutMenu from './FooterCheckOutMenu';
import classnames from 'classnames';

const FooterMenuWithFeatures = (props) => {
    const {isPaypalActive, isScriptLoaded, showCart, wish} = props;
    return (
        <div className={classnames('mobile-nav',{
            'checkout-nav':props.checkoutMenu,
        })}>
            {props.search ?
                <>
                    <SearchComponent availableIcons={props.availableIcons} webLangContext={props.webLangContext} searchInputValue={props.searchText} searchIcons={props.searchIcon} handleSearch={props.handleSearch}
                        updateSearch={props.updateSearchText} cancelSearchText={props.cancelSearchText} globalSearch={props.globalSearch}
                        updateSearchIcons={props.updateIconsInSearch}

                        />
                    </> : ''}
                {props.buyMenu && showCart ?
                    <FooterBuyMenu webLangContext={props.webLangContext} itemDetails={props.itemDetails} cartList={props.cartList} updateCart={props.updateCart} />
                    : ''}
                {props.checkoutMenu && showCart ?
                    <FooterCheckoutMenu showCart={showCart} webLangContext={props.webLangContext} userCreateOrder={props.userCreateOrder} cartList={props.cartList} wish={wish}
                     updateOrder={props.updateOrder} isPaypalActive={isPaypalActive} isScriptLoaded={isScriptLoaded} />
                    : ''}
                <FooterMenu showCart={showCart} webLangContext={props.webLangContext} cartList={props.cartList} waiterCalling={props.waiterCalling} cancelSearchText={props.cancelSearchText}
                changeWaiterCalling={props.callingWaiter} undoCallingWaiter={props.undoCallingWaiter} troubleShoot={props.troubleShoot} />
         </div>
    )
}

export default FooterMenuWithFeatures;
