import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Redirect, Switch } from "react-router-dom";
import { ROUTES_PATH } from '../../Constants/Routes';
import CategoryDashboardContainer from '../../Containers/CategoryListContainer';
import ItemListViewContainer from '../../Containers/ItemListViewContainer';
import ItemDetailsContainer from '../../Containers/ItemDetailsContainer';
import CheckoutContainer from '../../Containers/CheckoutItemContainers';
import CheckinContainer from '../../Containers/CheckinContainer';
import Booking from '../Booking';
import InvoiceContainer from 'src/Containers/InvoiceContainer';
import TroubleShootingContainer from 'src/Containers/TroubleShootingContainer';
import { CURRENCY_CODES } from '../Checkout/paypal/PaypalCurrencyCodes';
import { connect } from 'react-redux';
import SessionTimeoutManager from '../SharedComponent/SessionTimeoutManager';
import SessionExpired from '../SessionExpired';
import Actions from '../../Actions';


const RouterWrapper = (props) => {
    const location = useLocation();
    const locale = "en_US";
    const currency_code = CURRENCY_CODES.EUR; 
    const [isScriptLoaded, setScriptLoaded] = useState(false); 
    const isPaypalActive = props.paypal && props.paypal.isActive ? true : false; 
    const {subscribeWaiterCallApi, subscribeWaiterConfirmationApi, removeSubscriptions} = props;

    useEffect(() => {
        subscribeWaiterCallApi();
        subscribeWaiterConfirmationApi();

        return () => {
            console.log("<<<<<Removing All subscriptions>>>>>")
            removeSubscriptions();
            // Anything in here is fired on component unmount.
        }
    },[]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });           
    },[location.pathname]);

    useEffect(()=> {   
        console.info("PAYPAL=============================", props.paypal); 
        const isScriptLoaded = document.getElementById('paypalscript');
        if(props.paypal.paypalId && !isScriptLoaded) {
            console.info("Adding script", props.paypal); 
            const script = document.createElement("script");    
            script.async = true;    
            script.id = 'paypalscript'; 
            script.src = `https://www.paypal.com/sdk/js?client-id=${props.paypal.paypalId}&locale=${locale}&currency=${currency_code}`;    
            document.body.appendChild(script);
            // now wait for it to load...
            script.onload = () => {
                setScriptLoaded(true); 
                console.info("Script Loader", props.paypal); 
            } 
        } else {
            // setScriptLoaded(true);
        }   
    }, [props.paypal]);

    return (
        <div className="page-wrapper">
            <Switch>
                <Route exact path={ROUTES_PATH.ERROR} component={TroubleShootingContainer} />
                <Route exact path={ROUTES_PATH.CATEGORY_LIST} component={CategoryDashboardContainer} />
                <Route exact path={ROUTES_PATH.ITEM_LIST} component={ItemListViewContainer} />
                <Route exact path={ROUTES_PATH.ITEM_DETAILS} component={ItemDetailsContainer} />
                <Route exact path={ROUTES_PATH.CHECKOUT} render={(props) => <CheckoutContainer isScriptLoaded={isScriptLoaded} />} />
                <Route exact path={ROUTES_PATH.INVOICE} component={InvoiceContainer} />
                <Route exact path={ROUTES_PATH.BOOK} component={Booking} />
                <Route exact path={`${ROUTES_PATH.ROOT}`} component={CheckinContainer} />
                <Route exact path={`${ROUTES_PATH.SESSION_EXPIRED}`} component={SessionExpired} />
            </Switch>
            {<SessionTimeoutManager />}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        paypal: state.CategoryListReducer.paypal,
        
    }
}
const mapDispatchToProps = dispatch => ({
    subscribeWaiterCallApi: () => dispatch(Actions.CategoryList.subscribeWaiterCallApi()),
    subscribeWaiterConfirmationApi: () => dispatch(Actions.CategoryList.subscribeWaiterConfirmationApi()),
    removeSubscriptions: () => dispatch(Actions.CategoryList.removeSubscriptions()),
})


export default connect(mapStateToProps, mapDispatchToProps)(RouterWrapper);