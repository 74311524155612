import { connect } from 'react-redux';
import Action from '../Actions/index';
import CheckIn from '../Components/CheckIn/CheckIn';

const mapStateToProps = state => {
    return {
        userId: state.CategoryListReducer.userId,
        tableName: state.CategoryListReducer.tableName,
        webLangContext: state.CategoryListReducer.webLangContext,
        webLang: state.CategoryListReducer.webLang,
        businessName: state.CategoryListReducer.businessName,
        businessImage: state.CategoryListReducer.businessImage,
    }
}
const mapDispatchToProps = dispatch => ({
    userCheckin: (user) => dispatch(Action.CategoryList.userCheckin(user)),
    getUserDetails: (urlData) => dispatch(Action.CategoryList.getUserDetails(urlData)),
    getAllCategoryList: (userId) => dispatch(Action.CategoryList.getAllCategoryList(userId)),
    dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);