/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { APP_CONFIG } from '../../Constants/Config';
// import FooterMenuWithFeatures from '../Footer/FooterMenuWithFeatures';
import CheckoutItemCard from './CheckoutItemCard';
import FooterMenuContainer from '../../Containers/FooterMenuContainer';
import { CURRENCY_CODES } from './paypal/PaypalCurrencyCodes';
import { PaypalConstants } from './paypal/PayPalConstants'
import { FaHandHoldingHeart } from 'react-icons/fa';
import EmptyDataViewer from "../common/EmptyDataViewer";
import { GiBowlOfRice } from "react-icons/gi";
import CurrencyFormatter from '../SharedComponent/Currency';

const Checkout = (props) => {
    const pageText = props.webLangContext ? props.webLangContext.checkout : {};
    console.info("Checkout", props);
    const { isScriptLoaded } = props;
    const isPaypalActive = props.paypal && props.paypal.isActive ? true : false;
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const [cartList, setCartList] = useState([]);
    const [totalCartPrice, setTotalCartPrice] = useState(0);
    const [wish, setWish] = useState('');
    // const [isScriptLoaded, setScriptLoaded] = useState(false);

    const textRef = useRef();
    // const locale = "en_US";
    // const currency_code = CURRENCY_CODES.EUR;


   /*
    // Need to integrate it to prevent script to be loaded again and again after component loaded
    (function(){
        var desiredSource = 'https://sitename.com/js/script.js';
        var scripts = document.getElementsByTagName('script');
        var alreadyLoaded = false;

        for(var scriptIndex in document.scripts) {
            if(!alreadyLoaded && desiredSource === scripts[scriptIndex].src) {
                alreadyLoaded = true;
            }
        }
        if(!alreadyLoaded){
            // Run your code in this block?
        }
    })(); */

    /* useEffect(()=> {
        if(isPaypalActive) {
            const script = document.createElement("script");
            script.async = true;
            script.src = `https://www.paypal.com/sdk/js?client-id=${props.paypal.paypalId}&locale=${locale}&currency=${currency_code}`;
            document.body.appendChild(script);
            // now wait for it to load...
            script.onload = () => {
                setScriptLoaded(true);
            }
        } else {
            setScriptLoaded(true);
        }
    }, []); */

    const onChangeHandler = (e) => {
        const target = e.target;
        let height = target.scrollHeight;
        target.style.height = 'auto';
        target.style.height = `${height}px`;
        setWish(e.target.value);
        console.log("target", e.target.value)
    };

    const handleCartItemRendering = () => {

        if (cartList && !cartList.length) {
            return <React.Fragment>
                <EmptyDataViewer
                    component={GiBowlOfRice}
                    text={pageText.stillNotHungry}
                />
            </React.Fragment>
        }

        /* icon={GiBowlOfRice}
        width={33}
        height={33}
        cssClassName={'image-125'}
        loading={"lazy"}
        text={pageText.stillNotHungry} */

        return cartList.map(data => {
            return <CheckoutItemCard pageText={pageText} key={data.id}
                itemDetails={data} getItemDetailsFromTotalList={props.getItemDetailsFromTotalList}
                updateCart={props.updateCart} deleteItemFromCart={props.deleteItemFromCart} />
        })
    }

    useEffect(() => {
        console.log("Checkout props is: ", props);
        setCartList(props.cartList);
        let total = 0;
        props.cartList.forEach(cart => {
            total = total + (cart.count * cart.price);
            total = (Math.round((total + Number.EPSILON) * 100) / 100)
        })
        total = total.toFixed(2);
        setTotalCartPrice(total);
    }, [props.cartList])

    return (
        <div >
            <div className="mobile_chekout">

                {/* Buy item list */}
                {handleCartItemRendering()}
                {/* Buy Item List end */}
                {cartList && !!cartList.length && <React.Fragment>
                    <div className="order-total-wrapper"> {pageText.total} <CurrencyFormatter price={totalCartPrice} /> </div>
                    {/* <div className="text-block-308-copy"> {pageText.tax} {(totalCartPrice * 19) / 100} €</div> */}
                    <div className="wish-block">
                        <div className="wish-block-text-wrapper">
                            <FaHandHoldingHeart className="wish-icon" />
                            <div className="wish-block-text">{pageText.checkoutHeader}</div>
                        </div>
                        <div className="wish-block-textarea-wrapper">
                            <form id="email-form" name="email-form" data-name="Email Form" className="form-2">
                                <textarea placeholder={pageText.wish} maxLength="5000" id="field" name="field" className="wish-block-textarea"
                                    ref={textRef}
                                    onChange={onChangeHandler} />
                            </form>
                        </div>
                    </div>
                    <div className="placeHolderBottom"/>
                </React.Fragment>}
                <FooterMenuContainer isPaypalActive={isPaypalActive} isScriptLoaded={isScriptLoaded} checkoutMenu={true} wish={wish}/>
            </div>
        </div>
    )
}

export default Checkout;
