import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Action from '../Actions/index';
import CategoryDashboard from '../Components/Category/CategoryDashboard';

const mapStateToProps = state => {
    return {
        categoryList: state.CategoryListReducer.categoryList,
        businessName: state.CategoryListReducer.businessName,
        businessImage: state.CategoryListReducer.businessImage,
        itemList: state.CategoryListReducer.itemList,
        itemDetails: state.CategoryListReducer.itemDetails,
        cartList: state.CategoryListReducer.cartList,
        searchText: state.CategoryListReducer.searchText,
        searchIcon: state.CategoryListReducer.searchIcon,
        globalSearch: state.CategoryListReducer.globalSearch,
        webLangContext: state.CategoryListReducer.webLangContext,
        webLang: state.CategoryListReducer.webLang
    }
}

const mapDispatchToProps = dispatch => ({
    alterLanguage: () => dispatch(Action.CategoryList.alterLanguage()),
    getAllCategoryList: (userId) => dispatch(Action.CategoryList.getAllCategoryList(userId)),
    getItemList: (categoryId) => dispatch(Action.CategoryList.getItemList(categoryId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryDashboard));
