export const APP_CONFIG = {
    DEFAULT_RADIUS: 20,
    IMAGE_PATH: '/assets/images',
    DATE_FORMAT: 'DD.MM.YYYY, hh:mm',
    DEFAULT_LOCALE: 'de-DE',
    get LOCALE() {
        return this.DEFAULT_LOCALE
    },
    CURRENCY: 'EUR',
    ALLOW_GEOPOSITION: false,
    SESSION_TIMEOUT: 18000000000000, // 1 hours
    SESSION_INTERVAL: 1000000000 // 10 second - Check after every one second if session valid or not 
}