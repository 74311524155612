
export const CURRENCY_CODES = {
    AUD : 'AUD',
    BRL : 'BRL',
    CAD : 'CAD',
    CNY : 'CNY',
    CZK : 'CZK',
    DKK : 'DKK',
    EUR : 'EUR',
    HKD : 'HKD',
    HUF : 'HUF',
    ILS : 'ILS',
    JPY : 'JPY',
    MYR : 'MYR',
    MXN : 'MXN',
    TWD : 'TWD',
    NZD : 'NZD',
    NOK : 'NOK',
    PHP : 'PHP',
    PLN : 'PLN',
    GBP : 'GBP',
    RUB : 'RUB',
    SGD : 'SGD',
    SEK : 'SEK',
    CHF : 'CHF',
    THB : 'THB',
    USD : 'USD',
}