import React, { useEffect, useState } from 'react';
import { APP_CONFIG } from '../../Constants/Config';
import { Link } from 'react-router-dom';
import { ROUTES_PATH } from '../../Constants/Routes';
import { Validation } from 'src/Constants/constants';
import { useHistory } from "react-router-dom";
import { IoHome } from 'react-icons/io5';
import { MdRoomService } from 'react-icons/md';
import { FaShoppingCart } from 'react-icons/fa';

const FooterMenu = (props) => {
    const pageText = props.webLangContext ? props.webLangContext.footer : {};
    const [displayBell, setDisplayBell] = useState(true);
    const [bellAnimation, setBellAnimation] = useState(false);
    let history = useHistory();

    const callWaiter = () => {
        setDisplayBell(true);
        setBellAnimation(true);
        props.changeWaiterCalling()
            .then(data => {
                if (data === Validation.error) {
                    history.push(ROUTES_PATH.ERROR)
                }
            })

    }

    const undoCallWaiter = () => {
        setDisplayBell(true);
        setBellAnimation(false);
        if (props.waiterCalling) {
            props.undoCallingWaiter()
                .then(data => {
                    if (data === Validation.error) {
                        history.push(ROUTES_PATH.ERROR)
                    }
                })
        }
    }

    useEffect(() => {
        setBellAnimation(props.waiterCalling)
    }, [props.waiterCalling])

    console.log('dsdsd', bellAnimation)
    return (
        <div className="mobile-menu-wrapper">
            <div className={`mobile-menu`}>
                {displayBell ?
                    <>
                        <Link aria-current="page" className="mobile-menu-button" to={ROUTES_PATH.CATEGORY_LIST} onClick={() => props.cancelSearchText()}>
                            <IoHome className="navbar-icon" />
                            <span>{pageText.menu}</span>
                        </Link>
                        {!props.troubleShoot ? <div data-w-id="7aa821fd-41aa-ddf3-808a-8e61297e750a" className={`mobile-menu-button mobile_bell ${bellAnimation ? 'bellRingBell' : ''}`} onClick={() => setDisplayBell(false)}>
                            <svg className={`header-bar-icon navbar-icon ${bellAnimation ? 'animateBell' : ''}`} viewBox="0 0 24 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M 0.13411096,16.932947 H 23.865889 v 2.373177 H 0.13411096 Z M 14.183324,6.004459 A 2.3802973,2.3802973 0 0 0 12,2.6938759 2.3802973,2.3802973 0 0 0 9.8166764,6.004459 C 5.1771138,6.965596 1.6410788,10.91694 1.3206998,15.746358 H 22.6793 C 22.358921,10.91694 18.822886,6.965596 14.183324,6.004459 Z">
                                </path>
                            </svg>
                            <span>{pageText.waiter}</span>
                        </div> : ''}
                        {props.showCart && <Link className="mobile-menu-button shopping-cart" to={ROUTES_PATH.CHECKOUT}>
                            <FaShoppingCart className="navbar-icon" />
                            <span>{pageText.cart}</span>
                            {props.cartList && props.cartList.length > 0 ?
                                <div className="nr-items">
                                    {props.cartList.length > 0 ? props.cartList.length : null}
                                </div> : null}
                        </Link>}
                    </>
                    :
                    <>
                        <div className="call-waiter-wrapper">
                            <div data-w-id="7aa821fd-41aa-ddf3-808a-8e61297e7516" className="mobile-menu-button call-waiter-btn" onClick={callWaiter}>{pageText.yes}</div>
                            <div className="mobile-menu-button mobile_bell">
                            <svg className={`header-bar-icon navbar-icon ${bellAnimation ? 'animateBell' : ''}`} viewBox="0 0 24 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M 0.13411096,16.932947 H 23.865889 v 2.373177 H 0.13411096 Z M 14.183324,6.004459 A 2.3802973,2.3802973 0 0 0 12,2.6938759 2.3802973,2.3802973 0 0 0 9.8166764,6.004459 C 5.1771138,6.965596 1.6410788,10.91694 1.3206998,15.746358 H 22.6793 C 22.358921,10.91694 18.822886,6.965596 14.183324,6.004459 Z">
                                </path>
                            </svg>
                                <span>{pageText.callWaiter}</span>
                            </div>
                            <div data-w-id="7aa821fd-41aa-ddf3-808a-8e61297e7518" className="mobile-menu-button undo-call-waiter-btn" onClick={undoCallWaiter}>{pageText.no}</div>
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default FooterMenu;
