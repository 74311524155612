export const ROUTES_PATH = {
    ROOT: "/",
    CATEGORY_LIST: '/menu',
    ITEM_LIST: '/itemList',
    ITEM_DETAILS: '/itemDetails',
    CHECKOUT: '/checkout',
    BOOK: '/book',
    INVOICE: '/invoice',
    ERROR: '/error',
    SESSION_EXPIRED: '/session-expired'
}