/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { APP_CONFIG } from '../../Constants/Config';
import { Link } from 'react-router-dom';
import { ROUTES_PATH } from '../../Constants/Routes';
import { RiCloseCircleFill } from 'react-icons/ri';
import CurrencyFormatter from '../SharedComponent/Currency';

const CheckoutItemCard = (props) => {
    const pageText = props.pageText;
    const [displayCancel, setDisplayCancel] = useState(true);
    const product = props.itemDetails;

    const imgPath = APP_CONFIG.IMAGE_PATH;

    const redirectToItemPage = (event, props) => {
        props.getItemDetailsFromTotalList(product)
    }

    const getImageStyles = (product) => {
        let styles = {};
        if (product.images && product.images.length) {
            styles = {
                backgroundImage: `url(${product.images[0]}`
            }
        } else {
            styles = {
                backgroundImage: `url(${imgPath}/picture_dAsset-1.svg`,
                backgroundPosition: '25px',
                backgroundSize: '40% 50%'
            }
        }
        return styles;

    }

    const getProductPrice = () => {
        if (!product || !product.price)
            return null

        let price = Math.round(((product.price * product.count) + Number.EPSILON) * 100) / 100 ;
        return <CurrencyFormatter price={price} />
    }


    return (
        <div className="buyitem">

            {displayCancel ?
                <>
                    <div className="div-block-25"  >
                        <Link className="div-block-26-copy checkout-item-img" style={getImageStyles(product)} to={ROUTES_PATH.ITEM_DETAILS} onClick={(event) => redirectToItemPage(event, props)}></Link>
                        <div className="checkout-quantity-meter">
                            <div className="checkout-item-name">{product && product.name}</div>
                            <div className="checkout-item-price">{getProductPrice()} </div>
                            <div className="order-item-amount-wrapper">
                                {(product && product.count > 1) ? (<div data-w-id="99326b83-5e62-53a1-bee8-9e7464ebc8c4" className="minus-buy" onClick={(e) => {
                                    e.stopPropagation();
                                    return props.updateCart(product, product.Id, 'remove')
                                }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 383.35 82.06">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <path d="M191.61,82q-82.19,0-164.39,0C12.36,82,.63,70.82.64,56.05c0-9.79-1.27-19.54-.23-29.39C2.13,10.27,12.7.14,29.45.06c25.49-.11,51,0,76.45,0L353.74.08c14,0,24.53,7.22,28.23,19.41a13.16,13.16,0,0,1,.76,3.89c-.21,12,1.42,23.88.11,35.87C381.55,71,370.16,81.83,357.5,81.92c-28,.22-56,.11-83.94.12Z" />
                                        </g>
                                    </svg>
                                </div>) : <div className="empty-flex" ></div>}
                                <div data-w-id="16bca975-36dc-8e09-20bc-26cf20931378" className="order-item-amount">{product && product.count > 0 ? product.count : 'Buy'}</div>
                                <div className="plus-buy" onClick={(e) => {
                                    e.stopPropagation();
                                    return props.updateCart(product, product.Id, 'add')
                                }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 382.88 383.46">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <path d="M150.47,297c0-19.81-.11-39.63.1-59.44,0-3.81-1-4.71-4.74-4.69q-58,.19-116,.07c-14.92,0-24.57-6.88-28.75-20.06-1-3.25-1-6.56-1-9.87C0,195.34,0,187.68.07,180c.24-18,11.5-29.21,29.56-29.26,31-.09,62,0,93,0,7.66,0,15.34-.24,23,.06,3.93.16,5-1,4.94-4.93-.15-38.64-.05-77.27-.11-115.9,0-12.1,4.86-21.17,15.76-26.76A10,10,0,0,1,169,2.13c15-2.42,30.22-3.35,45.06.32,11.66,2.88,18.37,13.34,18.4,26.14q.12,43.71.05,87.43c0,10,.2,20-.13,30-.13,4.07,1.22,4.87,5,4.86,38.15-.14,76.31-.09,114.46-.08,19.89,0,31,10.95,31,30.68,0,8.83.18,17.67-.29,26.47-.68,12.7-10.86,23.14-23.5,24.6a75,75,0,0,1-8.47.44c-37.66,0-75.31.09-113-.08-4.12,0-5.27,1-5.25,5.19.18,38.13.11,76.27.1,114.4,0,20-10.86,30.86-31,30.77-9,0-18,.74-27-.43-14.39-1.89-24-12.76-24-27.37C150.39,336,150.47,316.48,150.47,297Z" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-remove-item">
                            <RiCloseCircleFill className="deleteitemicon" onClick={() => setDisplayCancel(!displayCancel)}/>
                        </div>
                    </div>
                </> :
                <>
                    <div className="remove-itemw-wrapper ">
                        <div data-w-id="f001c6a2-b487-da37-0456-149bda990797" onClick={() => setDisplayCancel(true)} className="text-block-314 cancelButton">{pageText.cancel}</div>

                        <div className="deleteitem deletButton" onClick={() => props.deleteItemFromCart(product.id)}>{pageText.delete}</div>
                    </div>
                </>
            }

        </div>
    )
}

export default CheckoutItemCard;