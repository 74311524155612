import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { APP_CONFIG } from '../../Constants/Config';
import { ROUTES_PATH } from '../../Constants/Routes';
import OutSideCloseHelper from '../SharedComponent/OutSideCloseHelper';
import { Link } from 'react-router-dom';
import './searchComponent.css';
import { TAG_ICONS } from 'src/Constants/data';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaChevronCircleDown } from 'react-icons/fa';
import { FaChevronCircleUp } from 'react-icons/fa';
import classnames from "classnames";


const SearchComponent = (props) => {
    console.log("availableIcons >>>>>>>", props.availableIcons)
    // alert("")
    const pageText = props.webLangContext ? props.webLangContext.footer : {};
    const filterIcons = props.webLangContext ? props.webLangContext.filterIcons : {};
    const location = useLocation();
    const modalRef = useRef(null);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [searchCode, setSearchCode] = useState('');
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const [showSearchFilter, setShowSearchFilter] = useState(false);

    const iconItems = TAG_ICONS;
    const [iconList, setIconList] = useState(iconItems);

    const handleIconFilterSelection = (value) => {
        let tempSelectedFilter = [...selectedFilter];
        if (tempSelectedFilter.includes(value)) {
            if (tempSelectedFilter.length == 1) {
                tempSelectedFilter = [];
            } else {
                let index = tempSelectedFilter.indexOf(value);
                tempSelectedFilter.splice(index, 1);
            }
        } else {
            tempSelectedFilter.push(value);
        }
        setSelectedFilter([...tempSelectedFilter]);
        if (props.globalSearch) {
            props.updateSearch(searchCode, tempSelectedFilter);

        } else {
            props.handleSearch(searchCode, tempSelectedFilter);
            props.updateSearchIcons(tempSelectedFilter);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        props.updateSearch(searchCode, selectedFilter);
    }

    const handleReset = (e) => {
        // e.preventDefault();
        /* if (props.globalSearch) {
            props.cancelSearchText(true);
        } else {
            setSearchCode('');
            setSelectedFilter([]);
            props.handleSearch('', []);
        } */
        console.log('global search', props.globalSearch)
        // e.preventDefault();
        props.cancelSearchText(true);
        setSearchCode('');
        setSelectedFilter([]);
        props.handleSearch('', []);


    }

    const handleSearchCodeChange = (e) => {
        setSearchCode(e.target.value)
    }

    // useEffect(() => {
    //     console.log("coming in useEffect", props.searchIcons && props.searchIcons.length > 0 && !props.globalSearch)
    //     if(props.searchIcons && props.searchIcons.length > 0 && !props.globalSearch){
    //         props.handleSearch('', props.searchIcons);
    //         }
    // }, [props.searchIcons])

    useEffect(() => {
        setSearchCode(props.searchInputValue);
        let tempIcons = props.searchIcons && props.searchIcons.length > 0 ? props.searchIcons : []
        setSelectedFilter([...tempIcons]);
    }, [props.searchInputValue, props.searchIcons])

    useEffect(() => {
        if (iconList && props.availableIcons) {
            const filteredIcons = iconList.filter(iconObj => props.availableIcons.includes(iconObj.name));
            setIconList(filteredIcons);
        }
    }, []);

    OutSideCloseHelper(modalRef, () => {
        if (showSearchFilter) {
            document.getElementById('filterToggler').style.transform = `translateY(0)`;
            setShowSearchFilter(false);
        }
    });

    if (props.globalSearch && !location.pathname.includes(ROUTES_PATH.ITEM_LIST)) {
        return <Redirect to={ROUTES_PATH.ITEM_LIST} />
    }

    console.log("iconList", iconList)
    return (
        <div id="filterToggler" data-hover="" data-delay="0"
            className={classnames('filterOpenBtn', {
                'w--open': showSearchFilter,
                'w--closed': !showSearchFilter,
            })}
            ref={modalRef}
        >
            <div className={`search-box-text ${selectedFilter.length > 0 ? 'filter-active' : ''}`} onClick={() => {
                setShowSearchFilter(!showSearchFilter);
                if (!showSearchFilter) {
                    let filterHeight = document.getElementById('filterWrapper').clientHeight;
                    document.getElementById('filterToggler').style.transform = `translateY(-${filterHeight}px)`;
                }
                else {
                    document.getElementById('filterToggler').style.transform = `translateY(0)`;
                }
            }}>
                <div className={`search-box-wrapper`}>
                    <AiOutlineSearch className={`header-bar-back-icon search-icon`} />{pageText.search} {showSearchFilter ? <FaChevronCircleDown className="search-bar-down-icon" /> : <FaChevronCircleUp className="search-bar-down-icon" />}
                </div>
                <div className="selected-filters-wrapper">
                    {iconList.map((icon, index) => {
                        return (
                            selectedFilter.includes(icon.name) ?
                                <React.Fragment key={index}>
                                    <div className={`selected-filter-item`}>
                                        {icon.data}
                                    </div>
                                </React.Fragment>
                                : ''
                        )
                    })}
                </div>
            </div>
            <nav
                className={classnames({
                    'w--open': showSearchFilter,
                    'w--closed': !showSearchFilter,
                })}
                id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1" id="filterWrapper">
                {iconList && <div className="filter-grid">

                    {iconList.map((icon, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={`filter-item ${selectedFilter.includes(icon.name) ? 'selectedFilter' : ''}`} onClick={() => handleIconFilterSelection(icon.name)}>
                                    {icon.data}
                                    <span>{filterIcons[icon.label]}</span>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>}
                <div className={`form-block w-form searchBar`}>
                    <form id="email-form" name="email-form" data-name="Email Form" className={`form`}>
                        <Link className="resetfilter w-button" onClick={e => handleReset(e)} to={ROUTES_PATH.CATEGORY_LIST}>
                            {pageText.reset}
                        </Link>
                        <input type="text" value={searchCode} onChange={e => handleSearchCodeChange(e)} className="text-field w-input" maxLength="256"
                            name="email-2" data-name="Email 2" placeholder="search" id="email-2" />
                        <input type="submit" value={pageText.apply} onClick={(e) => handleSearch(e)} data-wait="Please wait..." className={`submit-button w-button`} />
                    </form>
                </div>
            </nav>
        </div>
    )
}

export default SearchComponent;
