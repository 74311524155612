import { connect } from 'react-redux';
import Action from '../Actions/index';
import TroubleShooting from '../Components/Error/TroubleShooting';

const mapStateToProps = state => {
    return {
        errorMessage: state.CategoryListReducer.errorMessage
    }
}

const mapDispatchToProps = dispatch => ({
   
})

export default connect(mapStateToProps, mapDispatchToProps)(TroubleShooting);