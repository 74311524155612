import {connect} from 'react-redux';
import Action from '../Actions/index';
import Invoice from '../Components/Invoice';

const mapStateToProps = state => {
    return {
        invoice: state.CategoryListReducer.invoice,
        businessName: state.CategoryListReducer.businessName,
        webLangContext: state.CategoryListReducer.webLangContext,
        webLang: state.CategoryListReducer.webLang,
        errorMessage: state.CategoryListReducer.errorMessage,
        invoiceHtml: state.CategoryListReducer.invoiceHtml
    }
}

const mapDispatchToProps = dispatch => ({
    getItemDetailsFromTotalList: (item) => dispatch(Action.CategoryList.getItemDetailsFromTotalList(item)),
    sendInvoice: (userData) => dispatch(Action.CategoryList.sendInvoice(userData)),
    downloadInvoice: (orderId, userId) => dispatch(Action.CategoryList.downloadInvoice(orderId, userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
