import React from 'react';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from '../../Constants/Config';
import { ROUTES_PATH } from '../../Constants/Routes';
import ImageLoad from '../SharedComponent/Image';
import PlaceHoldermage from '../../images/picture_dAsset-1.svg';
import CurrencyFormatter from '../SharedComponent/Currency';


const ListViewItemCard = (props) => {
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const product = props.item;
    console.log("props", props);

    const getImageStyles = (product) => {
        let styles = {};
        if (product.images && product.images.length) {
            styles = {
                backgroundImage: `url(${product.images[0]}`
            }
        }
        return styles;

    }

    const getImagePath = (product) => {
        if(!product?.images?.length){
            return PlaceHoldermage;
        }
        return product.images[0];
    }

    return (
        <div className="product-item-wrapper">
            <Link to={ROUTES_PATH.ITEM_DETAILS} onClick={() => props.getItemDetails(product.id)} className={"item-picture"} >
                <div className="item-price">{product && <CurrencyFormatter price={product.price} /> }</div>
                <ImageLoad src={getImagePath(product)} alt="Product Image" />
                <div className="product-name"><span>{product && product.name}</span></div>
            </Link>
        </div>
    )
}

export default ListViewItemCard;

/* 


const ListViewItemCard = (props) => {
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const product = props.item;
    console.log("props", props);

    const getImageStyles = (product) => {

        if(!product?.images?.length){
            return PlaceHoldermage;
        }
        return product.images[0];
       
    }

    return (
        <div className="product-item-wrapper">
            <Link to={ROUTES_PATH.ITEM_DETAILS} onClick={() => props.getItemDetails(product.id)} className={"item-picture"} >
                <ImageLoad src={getImageStyles(product)} alt="Product Image" />
                <div className="item-price">{product && product.price}€</div>
                <div className="product-name"><span>{product && product.name}</span></div>
            </Link>
        </div>
    )
}

export default ListViewItemCard; */
/* 
style={getImageStyles(product)} className={"item-picture" + ((!product.images || !product.images.length) ? ' placeholder-nopicture' : '')} */