import React, { useEffect, useRef, useState } from 'react';
import { APP_CONFIG } from '../../Constants/Config';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { ROUTES_PATH } from '../../Constants/Routes';
import { itemDetailsImage, itemTypeIconImages, TAG_ICONS } from '../../Constants/data';
import FooterMenuContainer from '../../Containers/FooterMenuContainer';
import LanguageDropdown from '../SharedComponent/LanguageDropdown';
import OutSideCloseHelper from '../SharedComponent/OutSideCloseHelper';
import { HiArrowSmLeft } from 'react-icons/hi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { BsImageFill } from 'react-icons/bs';

const ItemDetails = (props) => {
    const modalRef = useRef(null);
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const taggedIcons = TAG_ICONS;
    const [displayMore, setDisplayMore] = useState(false);
    const [itemDetails, setItemDetails] = useState({});

    useEffect(() => {
        setItemDetails(props.itemDetails);
    }, [])

    const getCarouselImages = (product) => {
        let productImages = product.images;
        if (!productImages || !productImages.length)
            return <BsImageFill className="empty-img" />

        return productImages.map((imagePath, index) => {
            return <img src={imagePath} key={index} />
        });

    }

    const renderCarousel = (product) => {
        return <Carousel className="mask w-slider-mask">
            {getCarouselImages(product)}
        </Carousel>

    }

    const mapIcons = (icons) => {
        let tagsHtml = [];
        if (icons && icons.length) {
            taggedIcons.forEach((tags, index) => {
                icons.forEach((icon, indexInner) => {
                    if (tags.name.toLowerCase() === icon.id.toLowerCase()) {
                        tagsHtml.push(<img key={indexInner} src={imgPath + '/' + tags.image} loading="lazy" width="22" className="image-20" />)
                    }
                })
            })
        }
        return tagsHtml;
    }



    OutSideCloseHelper(modalRef, () => setDisplayMore(false));
    return (
        <div className="itembody">
            <div className="mobile_itemview">
                <div className="header-bar-item-details">
                    <Link className="header-bar-back-btn" to={ROUTES_PATH.ITEM_LIST} >
                        <HiArrowSmLeft className="header-bar-back-icon" />
                    </Link>
                    <LanguageDropdown alterLanguage={props.alterLanguage} webLang={props.webLang} />
                </div>
                {/* Slider */}
                <div className="item_slider">
                    <div className="slider w-slider">
                        {renderCarousel(itemDetails)}
                        {/* <div className="slide-nav w-slider-nav w-slider-nav-invert w-round"></div> */}
                    </div>
                </div>
                {/* Slider Exit */}
                {/* Item details */}
                <div className="itemtxtlayer">
                    <div className="iteminfo-title">{itemDetails && itemDetails.name}</div>
                    <div className="icons">
                        {mapIcons(itemDetails.icons)}
                    </div>
                    <div className="iteminfo-text" dangerouslySetInnerHTML={{
                        __html: itemDetails.information
                    }}></div>
                    {itemDetails?.extraInformation && <div className="more-wrapper" onClick={() => setDisplayMore(!displayMore)}>
                        <div className="moretxt">
                            <div className="more-btn-wrapper">
                                <div className="more-btn-placeholder"></div>
                                <span className="more-btn">Mehr</span>
                                {displayMore ? <RiCloseCircleFill className="more-btn-close" onClick={() => setDisplayMore(false)} /> : ''}
                            </div>
                            <div className="moretxtpop" style={displayMore ? { "display": "flex" } : { "display": "none" }} ref={modalRef} >
                                <div className="text-block-334" dangerouslySetInnerHTML={{
                                    __html: itemDetails.extraInformation
                                }}></div>
                            </div></div>
                    </div>
                    }
                    <div className="placeHolderBottom"></div>
                </div>
                <FooterMenuContainer buyMenu={true} itemDetails={itemDetails} />
            </div>
        </div>
    )
}

export default ItemDetails;