import React from 'react';
import { FaQrcode } from 'react-icons/fa';

const EmptyDataViewer = ({ component: Component, width, height, loading, cssClassName, text, alt, type = 'icon', imrSrc = '', iconSize = '3em'}) => {
    const getIcon = () => {
        if (type === 'icon') {
            return <Component size={iconSize} />
        }
        return <img
                src={imrSrc}
                width={width}
                height={height}
                className={cssClassName}
                loading={loading}
                alt={alt}
            />
    }
    
    return (
        <div className="empty-data-viewer">
            {getIcon()}
            <div className="text-block-343 text-center" dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    );
};

export default EmptyDataViewer;

/**
 * For Image icon
 * <EmptyDataViewer icon={GiBowlOfRice}
        width={33}
        height={33}
        cssClassName={'image-125'}
        loading={"lazy"}
        text={pageText.stillNotHungry} />
        
 */
