import axios from "../Helpers/axiosInstance";
import urlGenerator from "../Helpers/urlGenerators";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { baseCommonUrl, URLS } from "../Constants/UrlConstant";
import { REDUCER } from "../Constants/Reducer";
import { store } from "../Store/index";
import { Validation } from "src/Constants/constants";
import awsRequestInterceptor from "src/Helpers/awsRequestIntercepter";
import { getPaypalOrderRequest } from "src/Helpers/PaypalOrderCreator";
import { customAlphabet } from 'nanoid'

import * as mutations from "../graphQL/mutations";
import * as subscriptions from "../graphQL/subscriptions";
import Actions from ".";
import { APP_CONFIG } from "../Constants/Config";
import { INVOICE_DOWNLOAD_API, MENU_API } from "src/Helpers/endPoints";

let waiterCallSubscription = null;
let waiterCallConfirmSubscription = null;

const getAllCategoryList = (userId) => {
  return (dispatch) => {
    return axios
      .get(MENU_API(userId))
      .then((data) => {
        console.log("data", data);
        console.log("add", process.env.REACT_APP_API_URL);
        dispatch({
          type: REDUCER.GET_ALL_CATEGORYLIST,
          categoryList: data.data.Items,
          businessImage: data.data.metadata.businessImage,
          businessName: data.data.metadata.businessName,
          paypal: data.data.paypal || {},
          cashPayment: (data?.data?.cash?.isActive === false) ? false : true,
        });
        dispatch(Actions.CategoryList.getAvailableIcons(data.data.Items));
        return Validation.success;
      })
      .catch((err) => {
        console.log("error", err);
        if (err) {
          let errorMessage =
            err.errors && err.errors.length > 0
              ? err.errors.map((error) => {
                  return {
                    errorCode: error.errorCode,
                    errorMessage: error.message,
                  };
                })
              : [];

          dispatch({
            type: REDUCER.ERROR_MESSAGE,
            errorMessage: [...errorMessage],
          });
          return Validation.error;
        }
      });
  };
};

const getUserDetails = (urlData) => {
  return (dispatch) => {
    dispatch({
      type: REDUCER.USER_DATA,
      userId: urlData.userId,
      tableName: urlData.tableName,
    });
  };
};

const getAvailableIcons = (categoryList) => {
  let allIconsObject = [];
  categoryList.forEach((cat) => {
    if (cat.products) {
      cat.products.forEach((prod) => {
        if (prod.icons && prod.icons.length) {
          allIconsObject.push(...prod.icons);
        }
      });
    }
  });
  // console.log(allIconsObject);

  let allUniqueIcons = allIconsObject.map((icon) => icon.id);
  allUniqueIcons = [...new Set(allUniqueIcons)];
  console.log(allUniqueIcons);

  return (dispatch) => {
    console.log("Filter Icons here");

    dispatch({
      type: REDUCER.GET_AVAILABLE_ICONS,
      availableIcons: allUniqueIcons,
    });
  };
};

const getItemList = (categoryId) => {
  return (dispatch) => {
    let categoryList = store.getState().CategoryListReducer.categoryList;
    let category = categoryList.filter(
      (cat) => cat.categoryId == categoryId
    )[0];
    let productList = category ? category.products : [];
    dispatch({
      type: REDUCER.GET_ALL_LIST_ITEMS,
      itemList: productList && productList.length > 0 ? [...productList] : [],
      categoryName: !!category && category.categoryName,
    });
  };
};

const getItemDetails = (itemId) => {
  return (dispatch) => {
    let itemList = store.getState().CategoryListReducer.itemList;
    let searchResult = store.getState().CategoryListReducer.searchResult;
    let searchList = [...itemList];
    if (itemList.length == 0) {
      searchList = [...searchResult];
    }
    let item = searchList.filter((it) => it.id == itemId)[0];
    dispatch({
      type: REDUCER.GET_ITEM_DETAILS,
      itemDetails: item ? { ...item } : {},
    });
  };
};

const getItemDetailsFromTotalList = (item) => {
  return (dispatch) => {
    let categoryList = store.getState().CategoryListReducer.categoryList;
    let category = categoryList.filter(
      (cat) => cat.categoryId == item.categoryId
    )[0];
    let productList = category ? category.products : [];
    let itemDetails = productList.filter((it) => it.id == item.id)[0];
    console.log("detial", categoryList, category, productList, itemDetails);
    dispatch({
      type: REDUCER.GET_ITEM_DETAILS,
      itemDetails: itemDetails ? { ...itemDetails } : {},
    });
  };
};

const updateCart = (item, itemId, type) => {
  return (dispatch) => {
    let cartList = store.getState().CategoryListReducer.cartList;
    let itemExist, itemIndex;
    cartList.map((cart, index) => {
      if (cart.id == item.id) {
        itemIndex = index;
        itemExist = cart;
      }
    });
    if (itemExist && itemExist.id) {
      if (type === "add" && itemExist.count < 100) {
        itemExist.count = itemExist.count + 1;
      } else if (type === "remove") {
        itemExist.count = itemExist.count - 1;
      }
      cartList[itemIndex] = { ...itemExist };
    } else {
      cartList.push({
        ...item,
        count: type === "add" ? 1 : 0,
      });
    }
    let updatedCartList = cartList.filter((cart) => cart.count > 0);

    dispatch({
      type: REDUCER.UPDATE_BUY_LIST_ITEMS,
      cartList: updatedCartList ? [...updatedCartList] : [],
    });
  };
};

const deleteItemFromCart = (itemId) => {
  return (dispatch) => {
    let cartList = store.getState().CategoryListReducer.cartList;
    let itemExist, itemIndex;
    cartList.map((cart, index) => {
      if (cart.id == itemId) {
        itemIndex = index;
        itemExist = cart;
      }
    });
    let tempCartList = [...cartList];
    if (tempCartList.length == 1) {
      tempCartList = [];
    } else if (itemIndex >= 0) {
      tempCartList.splice(itemIndex, 1);
    }
    console.log("sdsd", tempCartList, itemId);
    dispatch({
      type: REDUCER.UPDATE_BUY_LIST_ITEMS,
      cartList: tempCartList ? [...tempCartList] : [],
    });
  };
};

const callingWaiter = () => {
  return (dispatch) => {
    const userId = store.getState().CategoryListReducer.userId,
      tableName = store.getState().CategoryListReducer.tableName;
    console.log("waiter calling", userId, tableName);

    const waiterInput = {
      tableName: tableName,
      userId: userId,
      hasCustomerCall: "true"
    }
    return API.graphql({
      query: mutations.callWaiter,
      variables: {
        waiterInput: waiterInput
      }
    })
      .then((data) => {
        let today = new Date();
        dispatch({
          type: REDUCER.UPDATE_WAITER_CALL,
          waiterCalling: true,
          waiterCallingDate: today.getDate(),
        });
        dispatch(subscribeWaiterConfirmationApi());
        return Validation.success;
      })
      .catch((err) => {
        console.log("error", err);
        let errorMessage =
          err.errors && err.errors.length > 0
            ? err.errors.map((error) => {
                return {
                  errorCode: error.errorCode,
                  errorMessage: error.message,
                };
              })
            : [];

        dispatch({
          type: REDUCER.ERROR_MESSAGE,
          errorMessage: [...errorMessage],
        });
        return Validation.error;
      });
  };
};

// TODO: Handle Errors - possible error could be in case Table name is not valid
const undoCallingWaiter = () => {
  return (dispatch) => {
    const userId = store.getState().CategoryListReducer.userId,
      tableName = store.getState().CategoryListReducer.tableName;

      const waiterInput = {
        tableName: tableName,
        userId: userId,
        hasCustomerCall: "false"
      }
    return API.graphql({
      query: mutations.callWaiter,
      variables: {
        waiterInput: waiterInput
      }
    })
      .then((data) => {
        dispatch({
          type: REDUCER.UPDATE_WAITER_CALL,
          waiterCalling: false,
          waiterCallingDate: "",
        });
        return Validation.success;
      })
      .catch((err) => {
        console.log("error", err);
        let errorMessage =
          err.errors && err.errors.length > 0
            ? err.errors.map((error) => {
                return {
                  errorCode: error.errorCode,
                  errorMessage: error.message,
                };
              })
            : [];

        dispatch({
          type: REDUCER.ERROR_MESSAGE,
          errorMessage: [...errorMessage],
        });
        return Validation.error;
      });
  };
};

//Subscribe to Call to Waiter confirmation API - graph QL
const subscribeWaiterConfirmationApi = () => {
  console.log("<<subscribeWaiterConfirmationApi>>");

  return (dispatch) => {
    if (waiterCallConfirmSubscription) return;

    console.log(waiterCallConfirmSubscription);
    const userId = store.getState().CategoryListReducer.userId;


    waiterCallConfirmSubscription = API.graphql({
        query: subscriptions.waitWaiterCallConfirmation,
        //graphqlOperation(subscriptions.waiterCallConfirmation),
        variables: {
          userId: userId
        }
      }
    ).subscribe({
      next: (res) => {
        console.log(res);
        if (
          res?.value?.data?.onConfirmWaiterCall?.table
        ) {
          waiterCallConfirmSubscription.unsubscribe();
          waiterCallConfirmSubscription = null;
          dispatch({
            type: REDUCER.UPDATE_WAITER_CALL,
            waiterCalling: false,
            waiterCallingDate: "",
          });
        }
        // this.onWaiterCallReceived(res.value.data.onWaiterCall.table);
        // Do something with the data
      },
    });
  };
};

const subscribeWaiterCallApi = () => {
  console.log("<<subscribeWaiterCallApi>>");

  return (dispatch) => {
    if (waiterCallSubscription) return;

    console.log(waiterCallSubscription);
    const userId = store.getState().CategoryListReducer.userId;


    waiterCallSubscription = API.graphql({
        query: subscriptions.callWaiterSubscription,
        //graphqlOperation(subscriptions.waiterCallConfirmation),
        variables: {
          userId: userId
        }
      }
    ).subscribe({
      next: (res) => {
        console.log(res);
        if (
          res?.value?.data?.onWaiterCall?.table
        ) {
          // waiterCallSubscription.unsubscribe();
          // waiterCallSubscription = null;
          dispatch({
            type: REDUCER.UPDATE_WAITER_CALL,
            waiterCalling: res?.value?.data?.onWaiterCall?.table?.hasCustomerCall,
            waiterCallingDate: "",
          });
        }
        // this.onWaiterCallReceived(res.value.data.onWaiterCall.table);
        // Do something with the data
      },
    });
  };
};

const removeSubscriptions = () => {

  return (dispatch) => {
    if(waiterCallSubscription){
      waiterCallSubscription.unsubscribe();
      waiterCallSubscription = null;
    }

    if(waiterCallConfirmSubscription) {
      waiterCallConfirmSubscription.unsubscribe();
      waiterCallConfirmSubscription = null;
    }
  };
}


const cancelSearchText = (iconReset) => {
  return (dispatch) => {
    dispatch({
      type: REDUCER.UPDATE_SEARCH_ITEMS,
      searchText: "",
      searchIcon: iconReset
        ? []
        : store.getState().CategoryListReducer.searchIcon,
      searchResult: [],
      itemList: [],
      globalSearch: false,
    });
  };
};

const updateSearchText = (search, selectedFilter) => {
  return (dispatch) => {
    search = search.toLowerCase();
    let tempCategoryList = store.getState().CategoryListReducer.categoryList;
    let tempResult = [];
    if (search || selectedFilter.length > 0) {
      let valid = false;
      tempCategoryList.map((category) => {
        valid = false;
        if (category.products && category.products.length > 0) {
          for (let i = 0; i < category.products.length; i++) {
            if (
              category.products[i].name
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              let tempValid = selectedFilter.length > 0 ? false : true;
              selectedFilter.map((filter) => {
                if (tempValid) {
                  return;
                } else {
                  tempValid = category.products[i].icons
                    .map((icon) => icon.id)
                    .includes(filter);
                }
              });
              if (tempValid) {
                tempResult.push(category.products[i]);
              }
            }
          }
        }

        return category;
      });
      dispatch({
        type: REDUCER.UPDATE_SEARCH_ITEMS,
        searchText: search,
        searchIcon: [...selectedFilter],
        searchResult: [...tempResult],
        itemList: [],
        globalSearch: true,
      });
    }
  };
};

const updateIconsInSearch = (icons) => {
  return (dispatch) => {
    dispatch({
      type: REDUCER.UPDATE_SEARCH_ICONS,
      searchIcon: [...icons],
    });
  };
};

const userCheckin = (userDetails) => {
  return (dispatch) => {
    let payload = {
      ...userDetails,
      userId: store.getState().CategoryListReducer.userId,
      tableName: store.getState().CategoryListReducer.tableName,
      sessionTimestamp: new Date().getTime().toString(),
    };
    return API.post(
      "testApi",
      URLS.UserCheckin,
      awsRequestInterceptor({ body: payload })
    )
      .then((data) => {
        dispatch({
          type: REDUCER.USER_CHECKIN,
          userDetails: { ...userDetails },
        });
        // Check in time
        dispatch({
          type: REDUCER.CHECKED_IN_TIME,
          checkedInTime: new Date().getTime(),
        });
        return Validation.success;
      })
      .catch((err) => {
        console.log("error", err);
        let errorMessage =
          err.errors && err.errors.length > 0
            ? err.errors.map((error) => {
                return {
                  errorCode: error.errorCode,
                  errorMessage: error.message,
                };
              })
            : [];

        dispatch({
          type: REDUCER.ERROR_MESSAGE,
          errorMessage: [...errorMessage],
        });
        throw { error: err };
      });
  };
};

const createOrder = ({ payMethod, wish, orderId }) => {
  payMethod = payMethod ? payMethod.toUpperCase() : "CASH";

  return (dispatch) => {
    let payload = [];
    let userId = store.getState().CategoryListReducer.userId;
    let tableName = store.getState().CategoryListReducer.tableName;
    let cartList = store.getState().CategoryListReducer.cartList;

    try {
      cartList.forEach((item) => {
        let nowTimeStamp = new Date().getTime();
        // const orderCompleteTime = nowTimeStamp + ((parseInt(item.duration , 10) || 1) * 1000);
        const productDurationTime = parseInt(item.duration, 10) || 1; // will be seconds

        payload.push({
          price: parseFloat(item.price, 10),
          quantity: parseInt(item.count),
          productId: item.id,
          productName: item.name,
          imageUrl: item.images && item.images.length ? item.images[0] : null,
          productNum: item.productNum,
          // finishedItemTs: orderCompleteTime, // Order
          timestampStart: nowTimeStamp,
          productDurationTime: productDurationTime,
        });
      });

      console.log("payload", payload, JSON.stringify(payload));
      console.log("userId", userId);
      console.log("tableName", tableName);

      // createOrder

      /*const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);
      const orderId = nanoid();*/

      let newpayload = {
        isPaid: payMethod === "PAYPAL" ? true : false,
        payMethod: payMethod,
        tableName: tableName,
        orderId: orderId,
        wish: wish,
        userId: userId,
        products: [...payload],
        incomingOrderTs: new Date(new Date().toISOString()).getTime(),
      };

      return API.graphql({
        query: mutations.createOrder,
        variables: {
          input: newpayload,
        },
      })
        .then((data) => {
          if (payMethod === "CASH") {
            dispatch({
              type: REDUCER.UPDATE_ORDER_INVOICE,
              invoice: newpayload,
            });

            console.log("order created with data", data);
            return Validation.success;
          } else {
            const orderData = data.data;
            console.log("orderData is :  ", orderData);
            const orderRequest = getPaypalOrderRequest({
              cartList: cartList,
              tableName: tableName,
              userId: userId,
              orderId: orderData.createOrder.orderId,
              currency_code: APP_CONFIG.CURRENCY,
              locale: APP_CONFIG.DEFAULT_LOCALE,
            });
            dispatch({
              type: REDUCER.UPDATE_ORDER_PAYMENT_PENDING,
              invoice: {
                orderId: orderData.createOrder.orderId,
                ...newpayload,
              },
            });

            console.log("data", data);
            return orderRequest;
          }
        })
        .catch((err) => {
          console.log("error creating order", err);
          let errorMessage =
            err.errors && err.errors.length > 0
              ? err.errors.map((error) => {
                  return {
                    errorCode: error.errorCode,
                    errorMessage: error.message,
                  };
                })
              : [];

          dispatch({
            type: REDUCER.ERROR_MESSAGE,
            errorMessage: [...errorMessage],
          });
          return Validation.error;
        });
    } catch (err) {
      console.log(err);
      let errorMessage =
        err.errors && err.errors.length > 0
          ? err.errors.map((error) => {
              return {
                errorCode: error.errorCode,
                errorMessage: error.message,
              };
            })
          : [];

      dispatch({
        type: REDUCER.ERROR_MESSAGE,
        errorMessage: [...errorMessage],
      });
    }
  };
};

const alterLanguage = () => {
  return (dispatch) => {
    dispatch({ type: REDUCER.ALTER_LANG });
  };
};

const setLanguage = (payload) => {
  return (dispatch) => {
    localStorage.setItem('_language',payload);
    dispatch({ type: REDUCER.SET_LANG, payload });
  };
};

const updateOrder = (orderAttributes) => {
  return (dispatch) => {
    let invoice = store.getState().CategoryListReducer.invoice;
    console.log("Old Invoice is: ", invoice);

    console.log("order attributes is: ");
    // createOrder

    let newpayload = {
      paymentMethod: "PAYPAL",
      isPaid: true,
      userId: store.getState().CategoryListReducer.userId,
      orderId: invoice.orderId,
      paypalOrderId: orderAttributes.paypalOrderID,
      paypalPayerId: orderAttributes.paypalPayerID,
    };

    console.log("Update Invoice request is: ", newpayload);

    return API.graphql({
      query: mutations.updatePayment,
      variables: {
        paymentInput: newpayload,
      },
    })
      .then((data) => {
        console.log("data is : ", data);
        dispatch({
          type: REDUCER.UPDATE_ORDER_INVOICE,
          invoice: {
            ...invoice,
            ...newpayload,
          },
        });
        console.log("order Data updated", data);
        return Validation.success;
      })
      .catch((err) => {
        console.log("error", err);
        return Validation.error;
      });
  };
};

const sendInvoice = ({ email, orderId }) => {

    return (dispatch) => {
        let userId = store.getState().CategoryListReducer.userId;
        try {
            const input = {
                email,
                timestamp: new Date(new Date().toISOString()).getTime(),
                userId,
                orderId
            };

            return API.graphql({query: mutations.sendInvoice, variables: {input}}).then((data) => {
                    console.log('Send Invoice Response Data: ',data);
                })
                .catch((err) => {
                    console.log("error creating order", err);
                    let errorMessage =
                        err.errors && err.errors.length > 0
                            ? err.errors.map((error) => {
                                return {
                                    errorCode: error.errorCode,
                                    errorMessage: error.message,
                                };
                            })
                            : [];

                    dispatch({
                        type: REDUCER.ERROR_MESSAGE,
                        errorMessage: [...errorMessage],
                    });
                    return Validation.error;
                });
        } catch (err) {
            console.log(err);
            let errorMessage =
                err.errors && err.errors.length > 0
                    ? err.errors.map((error) => {
                        return {
                            errorCode: error.errorCode,
                            errorMessage: error.message,
                        };
                    })
                    : [];

            dispatch({
                type: REDUCER.ERROR_MESSAGE,
                errorMessage: [...errorMessage],
            });
        }
    };
};

const downloadInvoice = (orderId, userId) => {
    return (dispatch) => {
        dispatch({type:REDUCER.SET_INVOICE_HTML,payload:''});
        let header = {
            'x-api-key': process.env.REACT_APP_INVOICE_AUTH_KEY,
            "Content-Type": "application/json",
          }
        const options = awsRequestInterceptor({ header,responseType:'text' });
        return axios
            .get(INVOICE_DOWNLOAD_API(userId, orderId), options)
            .then((response) => {
                dispatch({type:REDUCER.SET_INVOICE_HTML,payload:response.data});
            })
            .catch((err) => {
                console.log("error", err);
                if (err) {
                    let errorMessage =
                        err.errors && err.errors.length > 0
                            ? err.errors.map((error) => {
                                return {
                                    errorCode: error.errorCode,
                                    errorMessage: error.message,
                                };
                            })
                            : [];

                    dispatch({
                        type: REDUCER.ERROR_MESSAGE,
                        errorMessage: [...errorMessage],
                    });
                    return Validation.error;
                }
            });
    };
};

const CategoryList = {
  getAllCategoryList,
  updateCart,
  getItemList,
  getItemDetails,
  deleteItemFromCart,
  getItemDetailsFromTotalList,
  callingWaiter,
  undoCallingWaiter,
  cancelSearchText,
  updateSearchText,
  updateIconsInSearch,
  userCheckin,
  sendInvoice,
  createOrder,
  getUserDetails,
  updateOrder,
  alterLanguage,
  setLanguage,
  getAvailableIcons,
  subscribeWaiterConfirmationApi,
  subscribeWaiterCallApi,
  removeSubscriptions,
  downloadInvoice
};

export default CategoryList;
