import { REDUCER } from '../Constants/Reducer';
import { data } from '../Constants/data';
import englishLang from '../localization/en_lan.json';
import germanLang from '../localization/de_lan.json';
import {REHYDRATE} from 'redux-persist'

const CategoryListReducer = (state = {
    categoryList: [],
    itemList: [],
    itemDetails: {},
    cartList: [],
    searchText: '',
    searchIcon: [],
    searchResult: [],
    globalSearch: false,
    categoryName: '',
    userId: '',
    tableName: '',
    businessName: '',
    businessImage: '',
    paypal: {},
    cashPayment: {},
    engLang: {...englishLang},
    germanLang: {...germanLang},
    webLangContext: {...germanLang},
    webLang: "Deutsch",
    waiterCalling: false,
    waiterCallingDate: '',
    availableIcons: [],
    checkedInTime: null,
    isLocationAllowed: false,
    showCart: true,
    wish: '',
    invoiceHtml: ''
}, action) => {
    switch (action.type) {

        case REHYDRATE:
            console.log("jsssjs", action)
            if(action && action.payload){
                let today = new Date();
                const { waiterCalling = false, waiterCallingDate = '' } = action.payload.CategoryListReducer;
                let tempWaiterCall = waiterCalling ? (
                    waiterCallingDate ? (new Date(waiterCallingDate).getDate() <= today.getDate() ? true : false) : false
                ) : false
           return {
               ...state,
                 ...action.payload.CategoryListReducer,
                engLang: {...englishLang},
                germanLang: {...germanLang},
                webLang: action.payload.CategoryListReducer.webLang === 'Deutsch' ? "Deutsch" : "English",
                webLangContext: action.payload.CategoryListReducer.webLang === 'Deutsch' ? {...germanLang} : {...englishLang},
                waiterCallingDate: tempWaiterCall ? waiterCallingDate : '',
                waiterCalling: tempWaiterCall
             }
            }

        case REDUCER.UPDATE_WAITER_CALL:
            return {
                ...state,
                waiterCalling: action.waiterCalling,
                waiterCallingDate: action.waiterCallingDate
            }

        case REDUCER.GET_ALL_CATEGORYLIST:
            return {
                ...state,
                categoryList: action.categoryList,
                businessImage: action.businessImage,
                businessName: action.businessName,
                paypal: action.paypal,
                cashPayment: action?.cashPayment,
                showCart: (action?.paypal?.isActive) || ((action?.cashPayment === false) ? false : true)
            }
        case REDUCER.GET_AVAILABLE_ICONS:
            return {
                ...state,
                availableIcons: action.availableIcons
            }
        case REDUCER.CHECKED_IN_TIME:
            return {
                ...state,
                checkedInTime: action.checkedInTime
            }
        case REDUCER.USER_GEOLOCATION:
            return {
                ...state,
                isLocationAllowed: action.isLocationAllowed
            }
        case REDUCER.GET_ALL_LIST_ITEMS:
            return {
                ...state,
                itemList: action.itemList,
                categoryName: action.categoryName
            }
        case REDUCER.GET_ITEM_DETAILS:
            return {
                ...state,
                itemDetails: action.itemDetails
            }
        case REDUCER.UPDATE_BUY_LIST_ITEMS:
            return {
                ...state,
                cartList: action.cartList
            }
        case REDUCER.UPDATE_SEARCH_ITEMS:
            return{
                ...state,
                searchText: action.searchText,
                searchIcon: action.searchIcon,
                searchResult: action.searchResult,
                globalSearch: action.globalSearch,
                itemList: action.itemList
            }

        case REDUCER.UPDATE_SEARCH_ICONS:
            return {
                ...state,
                searchIcon: action.searchIcon,
            }
        case REDUCER.USER_DATA:
            return {
                ...state,
                userId: action.userId,
                tableName: action.tableName
            }
        case REDUCER.USER_CHECKIN:
            return {
                ...state,
                userDetails: action.userDetails,
            }
        case REDUCER.UPDATE_ORDER_INVOICE:
            return {
                ...state,
                invoice: action.invoice,
                cartList: []
            }
        case REDUCER.ALTER_LANG:
            return {
                ...state,
                webLang: state.webLang === "English" ? "Deutsch" : "English",
                webLangContext: state.webLang === "English" ? germanLang : englishLang,
            }
        case REDUCER.SET_LANG:
            console.log('in SET_LANG reducer case: ', action.payload);
            return {
                ...state,
                webLang: action.payload,
                webLangContext: action.payload === "English" ? englishLang : germanLang,
            }
        case REDUCER.ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        case REDUCER.UPDATE_ORDER_PAYMENT_PENDING:
            return {
                ...state,
                invoice: action.invoice,
                cartList: []
            }
        case REDUCER.UPDATE_WISH:
            return {
                ...state,
                wish: action.payload,
            }
        case REDUCER.SET_INVOICE_HTML:
            return {
                ...state,
                invoiceHtml: action.payload,
            }
        default:
            return state;
    }
}

export default CategoryListReducer;
