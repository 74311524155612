import React from 'react';
import FooterMenuContainer from 'src/Containers/FooterMenuContainer';
import { APP_CONFIG } from '../../Constants/Config';

const TroubleShooting = (props) => {
    console.warn(props);
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const getCurrentTime = () => {
        const d = new Date();
        return <div className="general-info-msg">{`${d.getDate()}.${d.getMonth()+1}.${d.getFullYear()}${'  '}${d.getHours()}:${d.getMinutes()} `}UTC</div>
        }

    const getErrorKeys = () => {
        if(props.errorMessage && props.errorMessage.length > 0){
            return props.errorMessage.map(error => {
                return <div className="text-block-340">{error.errorCode}</div>
            })
        }
         
    }

    const getErrorValues = () => {
        if(props.errorMessage && props.errorMessage.length > 0){
            return props.errorMessage.map((error, index) => {
                return <React.Fragment>
                        <div key={index} className="d-flex w-100 flex-wrap mb-2">
                            <div className="w-100">{error.errorMessage}</div>
                        </div>
                        {/* <div key={index} className="text-block-340">{error.errorMessage}</div> */}
                    </React.Fragment>
            })
        }
         
    }

    return (
        <div className="receipt_body">
            <div className="mobile_receipt">
                <div className="box-with-shadow">
                    <div className="general-info-msg">Troubleshoot</div>
                    <div className="troubleshoot_txt">Ups, something is wrong...</div>
                    <div className="columns w-row">
                        <div className="w-col w-col-6 w-col-small-6 w-col-tiny-6">
                            {/* getErrorKeys() */} {getErrorValues()}
                        </div>
                        <div className="w-col w-col-6 w-col-small-6 w-col-tiny-6">
                            
                        </div>
                    </div>
                    {getCurrentTime()}
                </div>
                <img className="troubleshoot-icon" src={`${imgPath}/error_personal_dAsset-1.svg`} loading="lazy" alt="" />
                <div className="troubleshoot_sorry">Please contact<br/>the Personal</div>
                <FooterMenuContainer
                    troubleShoot={true}
                     search={false}
                />
            </div>
            
        </div>
    )
}

export default TroubleShooting;