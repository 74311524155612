import React, { useState, useEffect } from "react";

import PlaceHoldermage from '../../../images/picture_dAsset-1.svg';

const ImageLoad = React.memo(({ src, placeholder, alt = "" }) => {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState(placeholder || PlaceHoldermage);

  
  useEffect(() => {
      // start loading original image
      
    const imageToLoad = new Image(100, 200);
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);
      updateSrc(src);
    };
    imageToLoad.onError = () => {
      setLoading(false);
      updateSrc(PlaceHoldermage);
    };
  }, [src]);

  // console.log("??????????????????", currentSrc , PlaceHoldermage,currentSrc.indexOf(PlaceHoldermage))

  const getContent = () => {
    if(loading) 
    return <div className="skeleton-loading"></div>
    return currentSrc.indexOf(PlaceHoldermage) === -1 ? <img className="product-image" src={currentSrc} alt={alt} /> : <img className="placeholder-image" src={currentSrc} alt="Default" />;
  }

  return getContent() 
});

export default ImageLoad;
