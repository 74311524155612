import { APP_CONFIG } from './Config';
export const data = [
    {
        "categoryname": "Tea Time",
        "id": "fba44ffc-8591-11eb-9519-2240f736cde7",
        "activeTime": {
            "timeEnd": "",
            "weekdays": {
                "tu": false,
                "mo": false,
                "su": false,
                "th": false,
                "fr": false,
                "we": false,
                "sa": false
            },
            "timeBegin": ""
        },
        "hide": "true",
        "products": [
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "100",
                "name": "Cofee",
                "information": "",
                "id": "11e5b298-867c-11eb-9276-6e593bee4bd8",
                "icons": [
                    "vegan", 'homemade', 'lactofree', 'vegetarian'
                ],
                "categoryid": "fba44ffc-8591-11eb-9519-2240f736cde7"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "20",
                "name": "Tea",
                "information": "",
                "id": "922d6a40-867c-11eb-9276-6e593bee4bd8",
                "icons": [
                    "vegan", 'homemade', 'alcohol'
                ],
                "categoryid": "fba44ffc-8591-11eb-9519-2240f736cde7"
            }
        ]
    },
    {
        "categoryname": "Lunch",
        "id": "591de7da-868a-11eb-bfd3-2e5849acb170",
        "hide": "false",
        "activeTime": {
            "timeEnd": "",
            "weekdays": {
                "tu": false,
                "mo": false,
                "su": false,
                "th": false,
                "fr": false,
                "we": false,
                "sa": false
            },
            "timeBegin": ""
        },
        "products": [
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "140",
                "name": "Daal rotti",
                "information": "",
                "id": "5cc41fe4-868f-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "beef", 'pork', 'alcohol', 'vegetarian'
                ],
                "categoryid": "591de7da-868a-11eb-bfd3-2e5849acb170"
            }
        ]
    },
    {
        "categoryname": "Dinner",
        "id": "380f02fc-867d-11eb-86d5-061a9a10ca00",
        "activeTime": {
            "timeEnd": "",
            "weekdays": {
                "tu": false,
                "mo": false,
                "su": false,
                "th": false,
                "fr": false,
                "we": false,
                "sa": false
            },
            "timeBegin": ""
        },
        "hide": "false",
        "products": [
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "150",
                "name": "Subway",
                "information": "",
                "id": "c8e8e89a-868e-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "beef", 'homemade', 'alcohol', 'pork'
                ],
                "categoryid": "380f02fc-867d-11eb-86d5-061a9a10ca00"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "100",
                "name": "Soup",
                "information": "",
                "id": "ef895a5c-868e-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "lactofree", 'vegan', 'alcohol'
                ],
                "categoryid": "380f02fc-867d-11eb-86d5-061a9a10ca00"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "150",
                "name": "Shahi Paneer",
                "information": "",
                "id": "15190c7c-868f-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "pork", 'vegan', 'vegetarian'
                ],
                "categoryid": "380f02fc-867d-11eb-86d5-061a9a10ca00"
            }
        ]
    },
    {
        "categoryname": "Breakfast",
        "id": "6d446060-8688-11eb-abbc-86582ffb28fb",
        "activeTime": {
            "timeEnd": "",
            "weekdays": {
                "tu": false,
                "mo": false,
                "su": false,
                "th": false,
                "fr": false,
                "we": false,
                "sa": false
            },
            "timeBegin": ""
        },
        "hide": "false",
        "products": [
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "50",
                "name": "Poha",
                "information": "",
                "id": "97175e12-868b-11eb-a59f-16a104ab55fa",
                "icons": [
                    "pork", 'vegan', 'homemade', 'alcohol'
                ],
                "categoryid": "6d446060-8688-11eb-abbc-86582ffb28fb"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "100",
                "name": "Egg Omelette",
                "information": "",
                "id": "8cd86c4c-868c-11eb-9330-66a10a1d05e0",
                "icons": [
                    "lactofree", 'vegan', 'homemade', 'alcohol'
                ],
                "categoryid": "6d446060-8688-11eb-abbc-86582ffb28fb"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "50",
                "name": "Bread and Butter",
                "information": "",
                "id": "9cd8aa66-868d-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "pork", 'vegan', 'homemade'
                ],
                "categoryid": "6d446060-8688-11eb-abbc-86582ffb28fb"
            },
            {

                "images": [

                ],
                "hide": false,
                "extraInformation": "",
                "price": "200",
                "name": "Sabji poori",
                "information": "",
                "id": "57015a32-868e-11eb-97fd-9abec02f6ff8",
                "icons": [
                    "pork", 'lactofree', 'homemade', 'beef'
                ],
                "categoryid": "6d446060-8688-11eb-abbc-86582ffb28fb"
            }
        ]
    }
]

export const itemDetailsImage = [
    {
        url: `${APP_CONFIG.IMAGE_PATH}/davide-cantelli-jpkfc5_d-DI-unsplash.jpg`
    },
    {
        url: `${APP_CONFIG.IMAGE_PATH}/sebastian-coman-photography-CRoAeTh5S_I-unsplash.jpg`
    },
    {
        url: `${APP_CONFIG.IMAGE_PATH}/kobby-mendez-idTwDKt2j2o-unsplash.jpg`
    },
    {
        url: `${APP_CONFIG.IMAGE_PATH}/kobby-mendez-q54Oxq44MZs-unsplash.jpg`
    },
    {
        url: `${APP_CONFIG.IMAGE_PATH}/cristina-matos-albers-Ltv7a5m8i4c-unsplash.jpg`
    },
]

export const itemTypeIconImages =
{
    vegan: `${APP_CONFIG.IMAGE_PATH}/leaf_dAsset-7.svg`,
    pork: `${APP_CONFIG.IMAGE_PATH}/pig_dAsset-1.svg`,
    homemade: `${APP_CONFIG.IMAGE_PATH}/home_s_dAsset-6.svg`,
    beef: `${APP_CONFIG.IMAGE_PATH}/beef_dAsset-4.svg`,
    lactofree: `${APP_CONFIG.IMAGE_PATH}/lactoAsset-1.svg`,
    fish: `${APP_CONFIG.IMAGE_PATH}/fishAsset-1.svg`,
    vegetarian: `${APP_CONFIG.IMAGE_PATH}/saladAsset-1.svg`,
    alcohol: `${APP_CONFIG.IMAGE_PATH}/alcoholAsset-1.svg`

}


export const TAG_ICONS = [
    {
        name: 'pork',
        label: 'pork',
        image: 'pig.svg',
        id: 1,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 984.19 711.44" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M875.07.06c-3.51,14-6.93,27.54-10.3,41.07-7,28.06-13.85,56.14-21,84.15-.78,3.08-.16,4.86,2,7,24.79,24.4,45.46,51.75,59.56,83.73,1.2,2.73,3,3,5.54,3,14.83-.06,29.66-.11,44.49,0,12.18.11,21.51,5.07,26.76,16.53a21.64,21.64,0,0,1,2.07,9.15q-.06,111.17-.07,222.33c0,13.16-12.48,25.37-25.65,25.42q-40.23.15-80.48,0a8,8,0,0,0-7.12,3.43c-13.88,17.8-29.33,34.08-47.58,47.49-2.73,2-3.1,4.3-3.09,7.26,0,43.3-.45,86.61.25,129.9.32,20-14.18,31.1-31.18,30.94-34-.3-68-.08-102-.09-19.81,0-31-11.18-31-31,0-24.48-.08-49,.1-73.45,0-4-.79-5.4-5.14-5.39q-104.48.21-209,0c-4.41,0-5.09,1.47-5.07,5.4.17,24.82-.46,49.65.26,74.44.52,18.2-13.75,30.16-30,30-34.66-.31-69.32-.08-104-.11-18.75,0-30.24-11.48-30.25-30.22q0-65.19.07-130.4a9,9,0,0,0-3.83-7.93c-44.47-35.36-76.15-79.69-93.29-134a246.81,246.81,0,0,1-11.88-74.58c0-4.72-1.52-5.74-5.94-5.68-19,.24-38,.11-57,.13-21.8,0-42.33-4.43-60.35-17.41C15.14,292.57,1.35,267.47.08,235.51c-1-24.68,6.69-46.38,23.07-64.59,19.62-21.82,44.49-33.17,74-33.93,7.85-.2,12,4,12,12q.09,15.24,0,30.48c0,8.21-3.92,12.1-12.26,12.36-16.81.52-29.78,7.67-37.95,22.55-13.81,25.17,4.85,58.34,33.76,59.18,24.31.71,48.65.17,73,.24,2.3,0,3.4-.36,3.71-3.16.63-5.56,4.93-10.47,3.07-16.54,4.42-3.38,2.06-9.34,5.26-13.38,3.95-5,4.25-11.66,6.87-17.41,9-19.85,19.12-39.06,32.26-56.54Q282.14,80,388.81,59.28A250.71,250.71,0,0,1,436,54.89q107,0,213.95,0a251.7,251.7,0,0,1,42.21,3.61,5.36,5.36,0,0,0,5.59-2c26.49-29.24,58.76-48.15,98-54.21C810.63,0,825.63-.1,840.63,0,851.94.15,863.24.06,875.07.06Zm-164,300.44a27.68,27.68,0,0,0,27.17,28.05c14.65.16,27-12.24,27.32-27.41.3-14.59-11.93-27.15-26.67-27.39A27.31,27.31,0,0,0,711.12,300.5Z"></path>
            </g>
        </svg>,
    },
    {
        name: 'vegan',
        label: 'vegan',
        image: 'leaf_dAsset-7.svg',
        id: 2,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.23 437.21" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M83.21,262.43c-2.95-20.07-1-39.85,4.42-59.16,15-53,48.36-89.84,99.34-110.5,19.79-8,40.67-11.07,62.05-11.08,25,0,50,1.11,75-.49,42.43-2.73,77.17-21.06,104.4-53.65A150.13,150.13,0,0,0,441.72,8.26c3.36-5.59,7.82-9,14.7-8.13,6.32.79,9.26,5.22,11.49,10.49,20.62,48.88,27.71,99.88,22.84,152.46S469.34,264.4,438.62,307.89c-22.33,31.62-50.29,57-84.42,75.18a227.16,227.16,0,0,1-77.64,24.55c-26.15,3.48-52.16,3.31-77.63-4.65a164.83,164.83,0,0,1-81.17-55.58c-3.11-3.88-4.59-3.82-8.16-.62C85.8,368.13,65.91,392.39,52,421.35c-7.36,15.29-23.51,19-35.85,13.37C1.5,428-3.89,411.89,2.92,397.21,23.19,353.6,54.28,319,92.39,290.33,137.3,256.57,188,236.31,243,226c27.92-5.23,56.12-7,84.44-7.8,10.4-.28,16.5-8.47,13.55-17.84-1.81-5.74-6.9-9.16-13.9-9.29-16.18-.31-32.3.81-48.38,2.29a426.76,426.76,0,0,0-103.07,22.09,369.28,369.28,0,0,0-87.08,44.07c-1.37.94-2.77,1.85-4.18,2.73C84.16,262.42,83.79,262.36,83.21,262.43Z" />
            </g>
        </svg>
    },
    {
        name: 'vegetarian',
        label: 'vegetarian',
        image: 'saladAsset-2.svg',
        id: 3,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437.51 437.13" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M218.63,245.9h202c12.24,0,17.53,5.72,16.07,17.79q-7.94,65.74-58.89,108.14c-13.3,11-27.84,20.06-44.15,25.89-4.34,1.56-6,3.63-5.61,8.27.94,12.37-3.47,22.18-14.85,28.14a27.28,27.28,0,0,1-13,2.9c-53.49-.07-107-.16-160.47.09-7.43,0-13.86-.79-19.92-5.66-6.43-5.17-10.35-11.65-9.86-19.44.57-9-2.85-12.67-11.11-16.29C55,376.52,24.71,344.13,8.33,299c-4.63-12.76-7-26-8.21-39.45-.61-6.63,5.22-12.94,12.05-13.55,2-.17,4-.1,6-.1Z" />
                <path d="M360.85,82a108.65,108.65,0,0,0-35.28,3.83c-39.49,10.55-72.32,47.78-78,88.23a112.28,112.28,0,0,0,1.55,40.59c.6,2.67.32,3.55-2.57,3.52-12.66-.11-25.32-.19-38,0-4.51.07-3.62-2.79-3.62-5.31q0-60,0-119.93c0-9.94-1.68-11.63-11.53-11.63-15.31,0-15.6.29-15.6,15.64V212.31c-1.93-1.76-3.26-2.88-4.48-4.1q-40.42-40.5-80.83-81c-6.09-6.1-8.89-6.1-15,0-1.89,1.88-3.8,3.74-5.65,5.66-4.55,4.72-4.58,8.18.1,12.85q27,27,54.14,54c5.85,5.83,11.68,11.68,18.26,18.27-2.44.11-3.85.22-5.26.22-19.83,0-39.66.16-59.48,0-40.15-.42-74.36-32.35-79-72.16-5-42.52,24.17-82.13,66.38-90.36a72.76,72.76,0,0,1,32.13,1.06c3.38.86,5,.13,6.54-3.08,6.62-14.12,16.54-25.49,28.83-35.16C151.07,5.31,170-.08,190.72,0c39.86.15,68.43,18.83,86.74,53.75,1.76,3.34,3.42,3.43,6.61,2.77,29.39-6.05,54.52,2.07,75.66,23.13C360.27,80.18,361.11,80.63,360.85,82Z" />
                <path d="M353.36,218.17c-23.31,0-46.62-.11-69.93.09-3.91,0-5.47-1.35-6.6-4.89-9-28.3,1.05-63,23.46-82.9,18.24-16.21,39.45-23.48,63.41-21.12,36.24,3.58,64.43,29.58,72,65.5a76.09,76.09,0,0,1-2.3,41.29c-.64,1.82-1.14,3.4-3.7,2.84-7.41-1.62-14.93-.72-22.4-.78C389.32,218.08,371.34,218.17,353.36,218.17Z" />
            </g>
        </svg>
    },
    {
        name: 'homemade',
        label: 'homemade',
        image: 'home_s_dAsset-6.svg',
        id: 4,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.86 436.85" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M356.34,84.59c0-14.65,0-28.31,0-42C356.41,32,361.44,27,372,26.94q25.25,0,50.49,0c10.43,0,15.66,5.18,15.67,15.62q.08,54.71-.05,109.42a10,10,0,0,0,3.78,8.53c15,12.95,29.79,26.15,44.65,39.27,7.9,7,8.45,14.78,1.54,22.59-5.07,5.74-10.15,11.47-15.32,17.12-6.81,7.46-14.61,7.94-22.4,1.37-3.89-3.27-7.64-6.71-12.17-10.71V236c0,57-.14,113.91.09,170.87.06,15.39-8.58,25.62-20.14,29.22a20.46,20.46,0,0,1-6.35.76q-165.21.06-330.43,0c-13,0-25-11.1-25.6-24-.54-11-.35-22-.36-33,0-49.46,0-98.93,0-149.5-3.9,3.42-7.25,6.39-10.63,9.32-3.56,3.09-7.3,6-12.31,5.9a15.18,15.18,0,0,1-10.6-4.93c-6.3-6.4-11.9-13.42-18.17-19.86-5.79-6-4.48-14.14,2.45-20.27Q31.94,177.7,57.85,155c53.46-47.15,107-94.18,160.23-141.63C226.28,6,234.49.38,245.67,0c10.24-.33,18.76,3.64,26.27,10.28Q312,45.7,352,81.07C353.22,82.13,354.5,83.1,356.34,84.59ZM246.71,273v0c11.48,0,23,0,34.46,0,12.76,0,20.31-7.29,20.38-20q.19-34.69,0-69.39c-.06-12-7.51-19.86-19.55-20q-35.2-.41-70.42,0c-11.54.13-19.36,7.36-19.53,18.47q-.54,36.18,0,72.38c.16,11.63,8,18.56,19.7,18.6S235.05,273,246.71,273Z" />
            </g>
        </svg>
    },
    {
        name: 'alcohol',
        label: 'alcohol',
        image: 'vine_dAsset-5.svg',
        id: 5,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.03 436.88" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M123,0q47,0,94,0c10.06,0,14.18,4.22,15.05,14.25,2.44,28.13,5.16,56.23,7.67,84.35,1.52,17.05,2.78,34.11,4.3,51.15.92,10.27,2.44,20.49,1.85,30.83-2.05,35.47-16.06,65.09-43.07,88.49a118.22,118.22,0,0,1-48.09,25.43c-3.43.9-4.79,2.31-4.77,6.1q.25,45.22,0,90.45c0,4,1.25,4.95,5.1,5,11.47.16,23-.78,34.43.62,15.48,1.88,28.06,14.62,29.28,32.87a6.9,6.9,0,0,1-6.69,7.23c-3.33.15-6.67,0-10,0h-165c-9.21,0-11.65-2.78-10-11.82,2.89-15.62,16.11-27.06,30-28.36,11.16-1,22.29-.66,33.43-.58,4.62,0,5.45-1.49,5.42-5.7q-.25-44.47,0-88.95c0-4.41-1.53-6-5.49-7.11-34.78-9.72-60.46-31-77.09-62.74C2.3,210.32-1.75,187.46.68,163.41,2.82,142.26,4.2,121,6.07,99.84c1.79-20.35,3.78-40.68,5.67-61C12.5,30.55,13.32,22.28,13.9,14,14.55,4.7,19.26,0,28.55,0Q75.79,0,123,0Zm0,109v0c23.65,0,47.3-.11,71,.09,4.23,0,5.48-1.13,5.09-5.31-1.88-20-3.56-40-5.29-60-.24-2.71-1.65-3.61-4.3-3.57-7.83.13-15.66.07-23.49.07-36,0-71.95.07-107.92-.09-4.3,0-6.15,1.27-6,5.59a56.17,56.17,0,0,1-.43,7c-1.54,17-3,34.06-4.7,51.07-.42,4.21.94,5.32,5.12,5.29C75.71,108.92,99.36,109,123,109Z" />
            </g>
        </svg>
    },
    {
        name: 'beef',
        label: 'beef',
        image: 'beef_dAsset-4.svg',
        id: 6,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.62 437.28" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M314.4.06a123.6,123.6,0,0,1,66.26,16.69c59.82,34.3,96.83,85.24,108.8,153.55,6.53,37.23,3,73.6-11.27,108.69-7.46,18.34-18.35,34.57-31.61,49.35a341.17,341.17,0,0,1-69.75,59.43A307.67,307.67,0,0,1,277,429.27c-21.44,4.79-43.21,6.71-65.15,7.79-17.65.88-35.08-1.14-52.51-2.55-14.91-1.22-29.42-5.68-44.05-9a172.16,172.16,0,0,1-44.6-16.89c-32.78-18.06-59.3-42.1-67.45-80.76-5.56-26.35-4.79-52.39,9.11-76.46,7.56-13.11,18.86-22.72,31.92-30.23,18.93-10.89,39.23-18.88,59.08-27.81,41.45-18.67,68.93-48.83,83.35-92.62C201.36,56.14,230.18,23,275.54,6.25A106.32,106.32,0,0,1,314.4.06ZM206.81,382.14a251.14,251.14,0,0,0,34.88-2.08c34-4.26,65.7-15.33,95.37-32.27,22-12.55,41.64-28.18,59.51-46,10.51-10.49,20.22-21.67,27-35.07,13.52-26.64,16.83-55,12.44-84.07-7.61-50.41-33.87-88.85-77.28-115.52-13.47-8.28-28.25-12.94-44.07-12.71-24.95.37-44,12.54-59.14,31.54-11.66,14.61-17.35,32-23.22,49.42a152.71,152.71,0,0,1-33.13,55.29c-23.16,25-51.69,42.24-82.33,56.35-15.27,7-30.91,13.33-44,24.3-21.34,17.83-24.27,42.31-7.62,64.64,10.24,13.73,24.49,21.86,40,28C137.82,376.86,172,381.53,206.81,382.14Z" />
                <path d="M196.87,354.06c-27.82-.23-55.12-3.5-81.11-14.14-9.62-3.94-18.64-9-25.62-16.9-8.93-10.15-8-22.34,2.13-31.27A70.48,70.48,0,0,1,109,281c21-10,42.2-19.87,61.71-32.76,37.07-24.5,68.28-54.29,85.2-96.53,3.58-8.94,6-18.34,9.26-27.4,4.08-11.27,10.26-21.45,19.21-29.4,17.32-15.4,36.56-17.59,57.18-6,32.14,18,53.52,44.93,63.68,80.42,7,24.42,7,48.81-.88,73.18-5.56,17.14-17.1,29.91-29.65,42-39.15,37.58-86.2,58.71-139.58,66.62A254.22,254.22,0,0,1,196.87,354.06ZM328.33,218.12a27.36,27.36,0,0,0,27.54-27.07c.09-15-12.88-27.82-27.74-27.48-15.3.35-27,12.62-26.83,28.23C301.43,206.27,313.7,218.22,328.33,218.12Z" />
            </g>
        </svg>
    },
    {
        name: 'ourtop',
        label: 'ourtop',
        image: 'starAsset-1.svg',
        id: 7,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 535 512.06" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon">
            <path d="M238.8,17.81l-65.3,132.4L27.4,171.51c-26.2,3.8-36.7,36.1-17.7,54.6l105.7,103-25,145.5c-4.5,26.3,23.2,46,46.4,33.7l130.7-68.7,130.7,68.7c23.2,12.2,50.9-7.4,46.4-33.7l-25-145.5,105.7-103c19-18.5,8.5-50.8-17.7-54.6l-146.1-21.3L296.2,17.81c-11.7-23.6-45.6-23.9-57.4,0Z" />
        </svg>
    },
    {
        name: 'spicy',
        label: 'spicy',
        image: 'pepperAsset-1.svg',
        id: 8,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 511.98" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M330.67,263.1V173.38l-52.75-24.22C219.44,218.74,197.58,400,56,400a56,56,0,0,0,0,112c212.64,0,370.65-122.87,419.18-210.34L438.13,263.1ZM461.76,134.73C493.92,74.89,477.18,26.46,458.62,3a8,8,0,0,0-11.25-1.21,6.67,6.67,0,0,0-.68.62l-22.9,23a8.06,8.06,0,0,0-.89,10.23c6.86,10.36,17.05,35.1-1.4,72.32a142.91,142.91,0,0,0-57.16-12c-28,0-54,8.54-76.34,22.59l74.67,34.29V231.1h89.09L506.44,288c3.26-12.62,5.56-25.63,5.56-39.31A154,154,0,0,0,461.76,134.73Z" />
            </g>
        </svg>
    },
    {
        name: 'seafood',
        label: 'seafood',
        image: 'fishAsset-3.svg',
        id: 9,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 320" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M327.1,0c-90,0-168.54,54.77-212.27,101.63l-87.33-66C15.37,26.4-2.74,36.18.36,50.24L24.54,160,.35,269.77c-3.1,14.06,15,23.83,27.14,14.66l87.33-66.05C158.55,265.23,237.13,320,327.1,320,464.56,320,576,192,576,160S464.56,0,327.1,0Zm87.43,184a24,24,0,1,1,24-24A24,24,0,0,1,414.53,184Z" />
            </g>
        </svg>
    },
    {
        name: 'fowl',
        label: 'fowl',
        image: 'chickenAsset-3.svg',
        id: 10,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437.82 436.97" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M244.67,300.41c-13.5,0-27,.07-40.5-.05a8.85,8.85,0,0,0-7,2.8q-14.74,14.93-29.79,29.58c-9.57,9.37-12.19,20.3-7.52,32.85,6.17,16.6,5.06,32.56-4.29,47.7-15.19,24.6-49.53,31.1-73.17,14.44C64.89,415.41,60,398.34,62.22,378.31c.4-3.51-.41-3.87-3.47-3.48-13.14,1.64-25.5-.38-36.79-7.81-26.09-17.17-29.63-57.65-6.85-78.92C31,273.28,49.35,269.58,69.64,276.63c14.43,5,26.21,2.19,36.59-9,8.7-9.41,18-18.28,27.18-27.24a9.9,9.9,0,0,0,3.29-7.83c-.15-22.15.07-44.3-.11-66.45-.25-30.49,6.33-59.16,22.68-85.15,12.59-20,27.78-37.81,46.76-52.17C232.34,8.84,262.06-.34,295,0a143.33,143.33,0,0,1,136.38,103c14.05,48.18,5.14,93.18-26.38,132.13-24.57,30.35-55.7,52.28-94.68,61.23-17.51,4-35.31,4.19-53.14,4C253,300.38,248.83,300.41,244.67,300.41Z" />
            </g>+
        </svg>
    },
    {
        name: 'organic',
        label: 'organic',
        image: 'organicAsset-4.svg',
        id: 11,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437.23 437.58" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <path d="M191.5,321.9V240.49c-20.84,8.3-41.11,7.61-60.7-3-11.79-6.39-21.33-15.43-27.28-27.42-13.87-27.95-10.59-62.37,19.5-87.2-6.55-5.68-12.91-11.47-17.28-19.09-13.41-23.37-13.81-47-.35-70.2,21-36.14,68.66-44.83,103.44-16.8a36.88,36.88,0,0,1,7,8c2,3,3.43,2.82,5.53.17A62,62,0,0,1,246.18,6.09c21.09-9,41.9-8.14,61.54,3.38,23.34,13.7,34.66,34.92,33.87,61.84C341,90.64,333,107.07,318,119.58c-3.27,2.72-3.32,3.8-.1,6.73,20.07,18.29,28.24,40.78,21.82,67.54-6.26,26.13-23.19,42.51-48.67,50a62.42,62.42,0,0,1-39.29-1.36c-1.7-.61-3.43-1.16-5.18-1.75-.83,4.14-1,75.12-.16,81.57,3.32-2.53,6.41-4.95,9.55-7.3,32.75-24.55,69.76-37.5,110.41-40.73,19-1.5,38-.6,56.95-.78,9.62-.08,14.91,6.93,13.73,16.55-4.53,36.94-21.24,67.74-48,93a189.87,189.87,0,0,1-79.42,45.13,329.17,329.17,0,0,1-34.38,7.43c-11.41,2-22.74,1.54-34.1,1.66-23.48.24-47,.72-70.43-.27-11.47-.49-22.94-3.51-34.18-6.3C92.84,419.83,56.47,397.08,29,361.21,12.48,339.65,3.11,314.85.08,287.79c-.81-7.24,4.81-14.16,12.33-14.19,28.94-.08,57.94-1.68,86.6,4.53,32.69,7.07,62.38,20.26,88.39,41.42.9.73,1.82,1.46,2.76,2.13C190.39,321.85,190.76,321.79,191.5,321.9ZM259.75,123c0-22.51-18.74-41.12-41.12-40.92A41,41,0,1,0,259.75,123Z" />
            </g></g></svg>
    },
    {
        name: 'ethnic',
        label: 'ethnic',
        image: 'globeAsset-1.svg',
        id: 12,
        isSelected: false,
        data: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423.79 423.91" fill="currentColor" strokeWidth="0" height="1em" width="1em" className="filterIcon" >
            <path d="M211.18,423.91C95.29,423.77,0,329.72,0,212.81,0,93.91,95.42-1.23,214.12,0,330.79,1.24,423.91,95.92,423.79,211.26,423.66,330.09,329.08,424.29,211.18,423.91Zm-2.88-294.1v0c10.5,0,21,.45,31.48-.13,9-.51,16.29,1.77,21.94,9.05,2.95,3.79,7.2,5.16,12,5a64.7,64.7,0,0,1,7.5.05c5.38.42,9.56,2.76,11.56,8.06,3,8-.18,14.77-9.16,17.95-12.86,4.54-25.89,8.63-38.83,13a13.52,13.52,0,0,1-11.08-.87c-4.72-2.46-9.51-4.84-14.39-6.95-15.39-6.66-30.32-5.23-44.25,3.75-8.94,5.77-17.44,12.25-25.89,18.75-10,7.68-16.52,17.6-18.57,30.3-1.19,7.31-.71,14.63-.54,21.92.49,20.29,15.7,38.49,35.47,43.09,10.37,2.41,20.88,1.42,31.34,1.61,10.27.18,15,4.8,15,15,0,7.82,0,15.65.07,23.48a69.29,69.29,0,0,0,5.82,27.64c5.07,11.35,13.54,16.4,25.84,15.21,8-.77,13.28-5.48,17.53-11.86C269,352,276.07,339.52,286.6,330c7.75-7,11.34-15.29,12.2-25.23a32.38,32.38,0,0,1,7.81-18.49,143.53,143.53,0,0,0,11.61-15.64c4.88-7.74,4.87-16.31,1.27-24.39-3.07-6.9-9.89-6.78-16.41-6.7-5.2.05-9.87-1.53-13-6.13-3.73-5.52-7.59-11-11.11-16.61-1.73-2.77-3.58-5.93-2.86-9.29,2.19-10.24,14.74-15.3,23.78-9.72,4.53,2.81,9,5.71,13.32,8.85,5.65,4.11,11.33,4.12,17.28.88a55.84,55.84,0,0,1,6.69-3.37c8.64-3.32,12.1-9.5,11.68-18.7-.4-8.69,5.35-14.25,14.07-14.56a75.11,75.11,0,0,1,9.49,0c4.47.4,4.9-1.3,3.75-5.18-2.86-9.59-6.08-19-10.66-27.95-26-50.77-66.82-82.23-122.87-93.71-3.22-.66-3.73.58-3.66,3.29.12,4.66.22,9.34,0,14-.54,10.28-2.22,12.44-13.49,13.56-5.77.58-11.65.09-17.48.12-6.42,0-11.88,2-14.88,8.14-4.81,9.78-12.64,15.09-23.35,16.38a11.9,11.9,0,0,0-2.42.6c-7.86,2.67-11.61,9.44-10.15,18.26,1.21,7.26,6.4,11.37,14.62,11.4C184,129.85,196.14,129.81,208.3,129.81Z" />
        </svg>
    }
]