import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Actions from 'src/Actions';
import OutSideCloseHelper from './OutSideCloseHelper';

const LanguageDropdown = (props) => {
    const modalRef = useRef(null);
    const [selectedLang, setSelectedLang] = useState(props.webLang === "Deutsch" ? 'DE' : 'EN');
    const [displayDropdown, setDisplayDropdown] = useState(false);
    OutSideCloseHelper(modalRef, () => setDisplayDropdown(false));
    const alternateLang = selectedLang === "EN" ? "DE" : "EN";
    return (
        <div className="language-btn" ref={modalRef} style={{ "zIndex": 901 }}>
            <div className="w--open">
                <div className="text-language" onClick={() => setDisplayDropdown(!displayDropdown)}>{selectedLang}</div>
            </div>
            {displayDropdown ? <nav className="languageDropdown">
                <div className="text-block-319" onClick={() => {setSelectedLang(alternateLang); setDisplayDropdown(false); props.alterLanguage()}}>{alternateLang}</div>
            </nav> : null}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        webLangContext: state.CategoryListReducer.webLangContext,
        webLang: state.CategoryListReducer.webLang
    }
}


const mapDispatchToProps = dispatch => ({
    alterLanguage: () => dispatch(Actions.CategoryList.alterLanguage()),
})


export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
