const baseUrl = process.env.REACT_APP_API_BASE_URL;
const environment = process.env.REACT_APP_STAGE_NAME;
const resource_Url = {
  invoice_download: `order/mobile/downloadInvoice`,
  menu: `menu`
};

const GET_BASE_URL = () => {
    return baseUrl;
}

const GET_RESOURCE_END_POINT = (url) => {
    return GET_BASE_URL()+'/'+environment + url;
}

const MENU_API = (userId) => {
    const apiUrl = `/${resource_Url.menu}/${userId}`;
    console.log(GET_RESOURCE_END_POINT(apiUrl));
    return GET_RESOURCE_END_POINT(apiUrl);
  };

const INVOICE_DOWNLOAD_API = (userId, orderId) => {
    const apiUrl = `https://vrq3c7uwwh.execute-api.eu-west-1.amazonaws.com/DEV/order/mobile/downloadInvoice?orderId=${orderId}&userId=${userId}`;
    return apiUrl;
    // return GET_RESOURCE_END_POINT(apiUrl);
};

export {
    GET_BASE_URL,
    INVOICE_DOWNLOAD_API,
    MENU_API
};
