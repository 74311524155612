/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import {ROUTES_PATH} from 'src/Constants/Routes';
import {Validation} from 'src/Constants/constants';
import {APP_CONFIG} from "../../Constants/Config";
import {store} from "../../Store";
import {customAlphabet} from "nanoid";
import {getPaypalOrderRequest} from "../../Helpers/PaypalOrderCreator";

let wishData = '';

const PayPalCheckout = (props) => {
    wishData = props.wishText;

    let history = useHistory();
    const paypal = useRef();

    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);
    const orderId = nanoid();


    useEffect(() => {
        // Doc Reference: https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-reference/
        // Payment Setup for Sellers: https://developer.paypal.com/docs/platforms/checkout/set-up-payments/


        window.paypal
            .Buttons({
                style: {
                    shape: 'pill',
                    color: 'white',
                    layout: 'horizontal',
                    label: 'paypal',
                    tagline: false,
                    height: 55
                },

                // onInit is called when the button first renders
                // onInit: (data, actions) => {
                // if something needs to be done on initialization
                // },

                // onClick is called when the button is clicked
                // onClick: (data, actions) => {
                // if some validation needs to be done on initialization
                // },

                createOrder: async (data, actions, err) => {
                    /* New Paypal payment flow */
                    let userId = props.userId;
                    let tableName = props.tableName;
                    let cartList = props.cartList;
                    /*const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);
                    const orderId = nanoid();*/
                    const orderRequest = getPaypalOrderRequest({
                        cartList: cartList,
                        tableName: tableName,
                        userId: userId,
                        orderId: orderId,
                        currency_code: APP_CONFIG.CURRENCY,
                        locale: APP_CONFIG.DEFAULT_LOCALE,
                    });
                    return actions.order.create(orderRequest);
                    /* New Paypal payment flow Ends*/
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log('captured order: ',order);
                    props.userCreateOrder({payMethod: 'PAYPAL', wish: wishData, orderId}).then(async orderResult => {
                        console.log("graphQL order created: ", orderResult);
                        if (orderResult) {
                            history.push(ROUTES_PATH.INVOICE);
                        } else {
                            console.error("Error while updating order");
                        }
                    });
                },

                onError: (err) => {
                    console.log("ERROR while proceeding with paypal ", err);
                }
            }).render(paypal.current);
    }, []);
    return (
        <>
            <div id="gastron-paypal-button" ref={paypal}/>
        </>
    );
};

export default PayPalCheckout;


