export const config = {
    devApiGateway:{
        REGION: "eu-west-1",
        URL: process.env.REACT_APP_CHECKIN_API
    },
    devCognito: {
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        REGION: process.env.REACT_APP_REGION,
        APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID
    }
}
