import React from "react";
import { connect } from 'react-redux';
// import { APP_CONFIG } from "../../Constants/Config";
import EmptyDataViewer from "../common/EmptyDataViewer";
import { FaQrcode } from 'react-icons/fa';

const SessionExpired = (props) => {
  // const imgPath = APP_CONFIG.IMAGE_PATH;
  // const qrCodeIcon = FaQrcode;
  const pageText = props.webLangContext ? props.webLangContext.sessionExpired : {};
  localStorage.removeItem('persist:root');

  return (
    <div className="mobile_chekout">
        <EmptyDataViewer 
            component={FaQrcode}
            text={`${pageText.session_timeout} <br /> ${pageText.scan_qr_code}`}
        />
    </div>
  );
};


const mapStateToProps = state => {
  return {
      webLangContext: state.CategoryListReducer.webLangContext
  }
}
export default connect(mapStateToProps)(SessionExpired);
;
