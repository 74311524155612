import React, {useEffect, useState} from "react";
import {toCurrency, transformDate} from "src/Helpers/Util";
import {APP_CONFIG} from "../../Constants/Config";
import FooterMenuContainer from "../../Containers/FooterMenuContainer";
import InvoiceActions from "./invoiceActions";
import JsPDF from 'jspdf';
import CurrencyFormatter from "../SharedComponent/Currency";
import ReactDOMServer from 'react-dom/server';
import DownloadInvoice from "../common/DownloadInvoice";
import GetInvoicePrice from "../common/GetInvoicePrice";

// import FooterMenuWithFeatures from "../Footer/FooterMenuWithFeatures";

const Invoice = (props) => {
    const pageText = props.webLangContext ? props.webLangContext.invoice : {};
    console.log(props);
    const {invoice,downloadInvoice,invoiceHtml} = props;
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const brandLogo = `${imgPath}/Logo_Main_DAsset-1.svg`;
    const brandLogoPng = `${imgPath}/Logo_Main_DAsset-1.png`;
    const [orderDateTime, setOrderDateTime] = useState(new Date());
    const [orderTotal, setOrderTotal] = useState(0);
    const payMethod = invoice.payMethod === 'CASH' ? pageText.paymentMethodCash : invoice.payMethod;
    const isCash = invoice.payMethod === 'CASH' ? true : false;

    useEffect(() => {
        if (invoice.products)
            calculateTotal();
    }, [invoice.products]);

    useEffect(() => {
        if(invoiceHtml){
            invoiceHtml && console.log('invoiceHtml',invoiceHtml);

            var printWin = window.open('','','left=0,top=0,toolbar=0,scrollbars=0,status  =0');
            printWin.document.write(invoiceHtml);
            printWin.document.close();
            printWin.focus();
            printWin.print();
            printWin.close();
 
            /*const doc = new JsPDF('p','pt','a6');
            doc.html(invoiceHtml,{
                // Adjust your margins here (left, top, right ,bottom)
                margin: [30, 10, 30, 10],
                callback: (pdf) => {
                    pdf.save('invoice.pdf');
                }
            })*/
        }

    }, [invoiceHtml]);
         
    const calculateTotal = () => {
        console.log(invoice.products);
        let total = invoice.products.reduce((sum, item) => sum + (item.quantity * parseFloat(item.price, 10)), 0);
        total = (Math.round((total + Number.EPSILON) * 100) / 100).toFixed(2);
        console.log(total);
        setOrderTotal(total);
    };

    const handleDownloadPdf = () => {
        console.log('Invoice: ',{invoice});
        const downloadInvoiceHtml = downloadInvoice(invoice.orderId,invoice.userId);
        console.log(downloadInvoiceHtml);

        /*const doc = new JsPDF('p','pt','a6');
        doc.html(downloadInvoiceHtml,{
            // Adjust your margins here (left, top, right ,bottom)
            margin: [30, 10, 30, 10],
            callback: (pdf) => {
                pdf.save('invoice.pdf');
            }
        })*/
        /*const downloadInvoiceHtml = ReactDOMServer.renderToString(<DownloadInvoice {...props} orderDateTime={orderDateTime} orderTotal={orderTotal} />);
        const doc = new JsPDF('p','pt','a6');
        doc.html(downloadInvoiceHtml,{
            // Adjust your margins here (left, top, right ,bottom)
            margin: [30, 10, 30, 10],
            callback: (pdf) => {
                pdf.save('invoice.pdf');
            }
        });
        // Name | Format | X | Y | W | H
        doc.addImage(brandLogoPng,'PNG',110,250,80,30);*/
    };

    return (
        <div className="receipt_body">
            <div className="mobile_receipt">
                    <div className="box-with-shadow">
                        <div className="general-info-msg">{pageText.thanksText}</div>
                        <div className="restaurant-name">{props.businessName}</div>

                        {invoice.products.map((product, index) => {
                            return <div key={index} className="d-flex w-100 flex-wrap mb-2">
                                <div className="w-75">{product.quantity} x {product.productName} </div>
                                <div
                                    className="w-25">{<GetInvoicePrice price={product.price} qty={product.quantity} />}</div>
                            </div>
                        })
                        }
                        <div className="d-flex w-100 flex-wrap mb-2">
                            <div className="w-75">{pageText.total}</div>
                            <div className="w-25">{<CurrencyFormatter price={orderTotal} />}</div>
                        </div>
                        <div className="d-flex w-100 flex-wrap mb-2">
                            <div className="w-75">{pageText.method}</div>
                            <div
                                className="w-25">{payMethod}</div>
                        </div>
                        <div className="d-flex w-100 flex-wrap mb-2">
                            <div className="w-75">{pageText.orderid}</div>
                            <div className="w-25">{invoice.orderId}</div>
                        </div>
                        {/* invoice.payMethod === "PAYPAL" && <div className="d-flex w-100 flex-wrap mb-2">
            <div className="w-75">Paypal Ref ID</div>
            <div className="w-25">{invoice.paypalOrderId}</div>
          </div> */}
          
         
                        <div className="general-info-msg">{transformDate(orderDateTime, 'DD.MM.YYYY HH:mm')}</div>

                        <img
                            src={brandLogo}
                            loading="lazy"
                            width="99"
                            height="99"
                            alt="qrcode"
                        />

                    </div>
                {/*<div className="px-3">
                    <InvoiceActions isCash={isCash} handleDownloadPdf={handleDownloadPdf} {...props} />
        </div>*/}
                <FooterMenuContainer checkoutMenu={true}/>
            </div>


        </div>
    );
};

export default Invoice;
