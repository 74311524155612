import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import combineReducers from '../Reducers/index';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import englishLang from '../localization/en_lan.json';
import germanLang from '../localization/de_lan.json';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import SetTransform  from './setTransform';


const createMiddleWare = applyMiddleware(thunkMiddleware, logger);

const migrations = {
  0: (state) => {
    return {
      ...state,
     }
  }
}

const persistConfig = { // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  blacklist: ['BellRingReducer'],
  debug: true,  
  // transforms: [SetTransform],
    stateReconciler: autoMergeLevel2,  
    migrate: createMigrate(migrations, { debug: true })
}


const persistedReducer = persistReducer(persistConfig, combineReducers)
 
const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  createMiddleWare // add any middlewares here
);

const persistor = persistStore(store);

export { store, persistor }

store.subscribe(() => {
})
