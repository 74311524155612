import { connect } from 'react-redux';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { APP_CONFIG } from '../../Constants/Config';
import { ROUTES_PATH } from "../../Constants/Routes";
import { REDUCER } from '../../Constants/Reducer';

const SessionTimeoutManager = (props) => {
    const { userId, tableName, checkedInTime, dispatch } = props;
    let history = useHistory();
    let sessionIntervalId = null;
    console.log("??>?>?>?>?>?>?>?>?>")

    const intervalDuration = APP_CONFIG.SESSION_INTERVAL;
    const sessionTimeoutDuration = APP_CONFIG.SESSION_TIMEOUT;

    const isSessionExpired = (mes) => {
        console.warn(">>Session ", mes)
        if(!checkedInTime){
            return ;
        }
        // console.log(sessionIntervalId);
        console.log('%c SessionTimeoutManager------------', 'background: #222; color: #bada55');
        const now = new Date().getTime();
        console.log(">>>>>>>>>>. checkedInTime", new Date(checkedInTime))
        console.log(">>>>>>>>>>. sessionTimeoutDuration", sessionTimeoutDuration)
        const accumulatedTime = checkedInTime + (sessionTimeoutDuration * 1000 );
        console.log(">>>>>>>>>>. accumulatedTime", new Date(accumulatedTime))
        if(now >= accumulatedTime) {
            terminateSessionAndLogout('Time Pass: Session terminated Successfully');
            return true;
        }

        return false;
    }

    const startSession = (msg) => {
        console.warn(msg);

        if(isSessionExpired('Something wrong with Session')) {
            return ;
        }

        if(!sessionIntervalId) {
            if(userId && tableName && checkedInTime) {
                try {
                    sessionIntervalId = setInterval( () => {
                        isSessionExpired('Session Interval Started'); 
                    }, intervalDuration);
                } catch(e) {
                    console.error('Error: Session Manager');
                    throw(e);
                }
            }
        }
    }

    const terminateSessionAndLogout = (msg) => {
        console.warn("TerminateSession", msg);
        console.log('%c Session terminated------------', 'background: #ff00ff; color: #bada55');
        localStorage.removeItem('persist:root');
        if(sessionIntervalId) {
            clearInterval(sessionIntervalId);
            sessionIntervalId = null;
             // clean CheckedIn time // done
            /* dispatch({
                type: REDUCER.CHECKED_IN_TIME,
                checkedInTime: null,
            }); */
    
            // clear cache and redirect to a new Page(Not checkedin page)
            // redirect; // done
            /* const encodedUrl = window.btoa(`userId=${userId}&&tableName=${tableName}`);
            const url = ROUTES_PATH.ROOT + `?${encodedUrl}`;
            history.push(url); */
        }
        history.push(ROUTES_PATH.SESSION_EXPIRED); 
        
       
        // Handle back button handling as well
    }

    useEffect(() => {
        // If Page is reloaded or window closed and reopened
        isSessionExpired('Page Loaded');

        return () => terminateSessionAndLogout('Session Component Unmount');
      }, []);

    if(userId && tableName && checkedInTime) {
        startSession('User Check IN Session ');
    }

    return (
        <React.Fragment>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        checkedInTime: state.CategoryListReducer.checkedInTime,
        userId: state.CategoryListReducer.userId,
        tableName: state.CategoryListReducer.tableName
    }
}
const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeoutManager);
