export const REDUCER = {
    GET_ALL_CATEGORYLIST: 'GET_ALL_CATEGORYLIST',
    GET_AVAILABLE_ICONS: 'GET_AVAILABLE_ICONS',
    CHECKED_IN_TIME: 'CHECKED_IN_TIME',
    USER_GEOLOCATION: 'USER_GEOLOCATION',
    GET_ALL_LIST_ITEMS: 'GET_ALL_LIST_ITEMS',
    GET_ITEM_DETAILS: 'GET_ITEM_DETAILS',
    UPDATE_BUY_LIST_ITEMS: 'UPDATE_BUY_LIST_ITEMS',
    UPDATE_WAITER_CALL: 'UPDATE_WAITER_CALL',
    UPDATE_SEARCH_ITEMS: 'UPDATE_SEARCH_ITEMS',
    UPDATE_SEARCH_ICONS: 'UPDATE_SEARCH_ICONS',
    USER_CHECKIN: 'USER_CHECKIN',
    USER_DATA: 'USER_DATA',
    UPDATE_ORDER_INVOICE: 'UPDATE_ORDER_INVOICE',
    ALTER_LANG: "ALTER_LANG",
    ERROR_MESSAGE: "ERROR_MESSAGE",
    UPDATE_ORDER_PAYMENT_PENDING: 'UPDATE_ORDER_PAYMENT_PENDING',
    UPDATE_WISH: 'UPDATE_WISH',
    SET_LANG: 'SET_LANG',
    SET_INVOICE_HTML: 'SET_INVOICE_HTML',
}
