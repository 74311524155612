

export const waitWaiterCallConfirmation = `
subscription onConfirmedWaiterCall ($userId: String!){
    onConfirmWaiterCall(userId: $userId) {
      table {
        hasCustomerCall
        tableName
        userId
      }
    }
  }
  `
;

export const callWaiterSubscription = `
subscription onCallWaiter ($userId: String!){
  onWaiterCall(userId: $userId) {
      table {
        allOrdersFinished
        hasCustomerCall
        isOverTime
        tableName
        userId
      }
    }
  } 
`;