import { REDUCER } from '../Constants/Reducer';

const BellRingReducer = (state = {

    
}, action) => {
    switch (action.type) {

       
        default:
            return state;
    }
}

export default BellRingReducer;